
/** Создан что бы открывать popup и запрашивать информацию о ресторане
 *  */


import { createSlice } from '@reduxjs/toolkit'

const popupRestorantsSlice = createSlice({
  name: 'popupAboutRestorants',
  initialState: {
    value: null,
  },
  reducers: {
    openPopup: (state, action) => {
      state.value = action.payload

      console.log(state.value);
    },
    closePopup: (state, action) => {
        state.value = null;
      },
  },
})

export const { closePopup, openPopup } = popupRestorantsSlice.actions

export default popupRestorantsSlice.reducer
