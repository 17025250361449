import React from 'react';
import { Select, Space, Typography } from 'antd';
import { ManagerOfferContext } from 'context'
import handlerAddFilter from '../utils/handlerAddFilter';

export default function FilterNumberOfFloors()  {
const {  
        setDataForFilterHalls
  } = React.useContext(ManagerOfferContext);

// запись нового значения
const handleChange = (item) => {
handlerAddFilter(item, setDataForFilterHalls, "numberOfFloors" );  
};


  return(
    <Space wrap>
      <Typography.Paragraph level={4}>
        Выбор этажа:
      </Typography.Paragraph>
    <Select
      defaultValue="Выберите этаж"
      style={{
        width: 150,
        paddingLeft: 10
      }}
      onChange={handleChange}
      options={[
        {
          value: 1,
          label: '1 этаж',
        },
        {
          value: 2,
          label: '2 этаж',
        },
        {
          value: 3,
          label: '3 этаж',
        },
        {
          value: 4,
          label: '4 этаж',
        },
        {
          value: 5,
          label: '5 этаж',
        },
        {
          value: 6,
          label: '6 этаж',
        },
      ]}
    />
  </Space>
    
  );
}
