import { useSelector } from "react-redux"; 
import useHandle from "../hooks/useHandle";
import { Button } from "antd";
import { useEffect, useState } from "react";

export default function BuildRout() {
  const widgetMapData = useSelector((state) => state.widgetMap);
  const { onBuild } = useHandle();

  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (
      widgetMapData.first.coordinates.length > 0 &&
      widgetMapData.second.coordinates.length > 0
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [widgetMapData.first.coordinates, widgetMapData.second.coordinates]);

  return (
    <Button
      type="primary"
      disabled={isDisable}
      onClick={() =>
        onBuild(
          widgetMapData.first.coordinates,
          widgetMapData.second.coordinates
        )
      }
    >
      Построить маршрут
    </Button>
  );
}
