import styles from "./UserLayout.module.scss";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setUser } from "store";
import { Button } from "components";
import { classNames } from "utils";
import { ROUTES } from "router/routes";

export default function UserHeader({ user }) {
  // состояние ссылки
  const [toLink, setToLink] = React.useState(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // определяем где мы находимся на карте или в админке
  const url = window.location.href;
  
  const isAdmin = React.useMemo(() => {
    if (url.includes("/map")) {
      setToLink(true);
    }
  }, [url]);

  function handleLogout() {
    localStorage.setItem("token", null);
    dispatch(setUser(null));
    navigate("/auth");
  }

  return (
    user && (
      <div
        className={classNames(styles.header, [
          pathname !== ROUTES.MANAGER.CHILDREN.WORKSPACE.PATH && ROUTES.MANAGER.CHILDREN.MAP  , //styles.offset 
        ])}
      >
        <div className="wrapper">
          <div className={styles.row}>
            <div className={styles.email}>{user.email}</div>
            {/* <a href="/manager/map">Переход на NEW карту VK </a>
            <a href="/admin/users">Переход в админку</a>
            <a href="/manager/workspace">Переход на карту</a> */}
            {toLink ? (
              <a href="/admin/users">Переход в админку</a>
            ) : (
              <a href="/manager/map">Переход на карту</a>
              
            )} <a href="/manager/workspace">OLD карта</a>
            <Button
              text="Выйти"
              className={styles.button}
              onClick={handleLogout}
            />
          </div>
        </div>
      </div>
    )
  );
}
