import { createSlice } from '@reduxjs/toolkit'

const searchRestaurantsSlice = createSlice({
  name: 'restaurants',
  initialState: {
    value: [],
  },
  reducers: {
    searchRestaurants: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { searchRestaurants } = searchRestaurantsSlice.actions

export default searchRestaurantsSlice.reducer
