import "./MyMarker.css";
import { ManagerOfferContext } from "context";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import PropTypes from "prop-types";

import cemetery from "../../../../../assets/icon/marker-cemetery.svg";
import crematory from "../../../../../assets/icon/marker-crematory.svg";
import morgue from "../../../../../assets/icon/marker-morgue.svg";
import metro from "../../../../../assets/icon/marker-metro.svg";
import restaurant_1 from "../../../../../assets/icon/marker-restaurant-1.svg";
import restaurant_2 from "../../../../../assets/icon/marker-restaurant-2.svg";
import restaurant_3 from "../../../../../assets/icon/marker-restaurant-3.svg";
import restaurant_4 from "../../../../../assets/icon/marker-restaurant-4.svg";
import restaurant_5 from "../../../../../assets/icon/marker-restaurant-5.svg";
import zone from '../../../../../assets/icon/marker-zone.svg'
import district from '../../../../../assets/icon/marker-districk.svg'

MyMarker.propTypes = {
  coordinates: PropTypes.array.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string, // работаем / работаем / закрыт и т.д.
  priority: PropTypes.number, // 1, 2, 3, 4, 5 а так же по объектам отсутстует
  selectRestaurants: PropTypes.func,
};

export default function MyMarker({
  coordinates,
  type = "RESTAURANT",
  title,
  address,
  id,
  status,
  priority,
  selectRestaurants,
}) {
  const { setModalFor, modalFor } = React.useContext(ManagerOfferContext);
  
console.log(type)

  function buildMarker() {
    let iconMarker;
    let popupMarker;

    const priorityRestaurant = {
      1: restaurant_1,
      2: restaurant_2,
      3: restaurant_3,
      4: restaurant_4,
      5: restaurant_5,
    };

    const popupObject = (
      <Popup>
        Тип: {type}
        <br /> Название: {title}
        <br /> Адрес: {address}
        <br /> id: {id}
        <br />
      </Popup>
    );
    switch (type) {
      case "RESTAURANT":
        iconMarker = priorityRestaurant[priority];
        popupMarker = <></>;
        break;
      case "CEMETERY":
        iconMarker = cemetery;
        popupMarker = popupObject;
        break;
      case "METRO":
        iconMarker = metro;
        popupMarker = popupObject;
        break;
      case "MORGUE":
        iconMarker = morgue;
        popupMarker = popupObject;
        break;
      case "CREMATORY":
        iconMarker = crematory;
        popupMarker = popupObject;
        break;
      case "ZONE":
        iconMarker = zone;
        popupMarker = popupObject;
        break;
      case "DISTRICT":
        iconMarker = district;
        popupMarker = popupObject;
        break;
      default:
        popupMarker = <></>;
        iconMarker = restaurant_1;
        console.log("Не удалось определить тип объекта при отрисовке на карте");
        break;
    }

    return { iconMarker, popupMarker };
  }

  function handlMarcer() {
    if (type === "RESTAURANT") {
      setModalFor(id);
    }
  }

  function statusContainer(status) {
    return `<div class='status'>${status}<div class='triangle'><div> </div>`;
  }

  const buildStatus =
    status == "Работаем" 
      ? false
      : status == "Не работаем"
      ? statusContainer("Не работаем")
      : status == "Работаем по согласованию"
      ? statusContainer("По согласованию")
      : status == "Закрыт"
      ? statusContainer("Закрыт")
      : type != 'RESTAURANT'
      ? false     
      : statusContainer("Статус не определен");

  const isOpen = `<div class='selected'>Выбрн этот ресторан<div class='triangle'><div> </div>`;

  if (id == modalFor) {
    console.log(coordinates);
    selectRestaurants(coordinates);
  }

  return (
    <Marker
      position={coordinates}
      icon={L.divIcon({
        className: "custom-div-icon",
        html: `<div class='marker-pin-object'><img src='${
          buildMarker().iconMarker
        }'/>${id == modalFor ? isOpen : ""}${
          buildStatus ? buildStatus : ""
        }</div>`,
        //html: `<div class='marker-pin'><div class='marker-pin-in'></div></div> <i class='marcer-number'>${ isHandleWidgetT && givStateNumber(i.client)}</i>`,
        iconSize: [30, 42],
        iconAnchor: [23, 60],
      })}
      eventHandlers={{
        click: (e) => {
          handlMarcer(e);
        },
      }}
    >
      {buildMarker().popupMarker}
    </Marker>
  );
}
