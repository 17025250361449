//@ts-check
import React from "react";
// @ts-ignore
import { MODELS } from "constants";
import { api } from "utils";

export default function useSearchOnBD() {
  function getRestaurants(search, callback) {
    if (search.length > 0) {
      api
        .getAll({
          model: MODELS.RESTAURANT.VALUE,
          params: {
            where: { isPublished: true },
            search: { keys: ["title", "cardTitle", "address"], value: search },
            attributes: [
              "id",
              "cardTitle",
              "title",
              "point",
              "address",
              "point",
            ],
            limit: 3,
          },
        })
        .then(({ data }) => {
          callback(data.rows);
        });
    } else {
      callback([]);
    }
  }

  function getRestoranById(id, callback) {

    api
        .getOne({ model: MODELS.RESTAURANT.VALUE, id })
         .then(({ data }) => {          
          callback(data)           
          })
  }

  return { getRestaurants, getRestoranById };
}
