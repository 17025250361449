import useHandle from "../hooks/useHandle";
import { Input } from 'antd';

const ImputSerch = ({ onInputlist, valueInput, type }) => {
  const { handle } = useHandle(onInputlist, type);
  console.log("valueInput", valueInput)

  return <Input placeholder="Введите адрес" value={valueInput} onInput={(e) => handle(e)}  />;
};

export default ImputSerch;
