import { MODELS } from 'constants';
import { saveExel, startExporsHalls } from 'hooks';
import { api, postToGoogleDheets } from 'utils'

export default async function handleSave({
  data,
  model,
  onSave,
  onError,
  id = undefined,  
}) {

  // const {exportAllinFileXlsx} = useExports();
  const settings = { model, data }  

  if (id) {
    settings.id = id    
  }

  // если нет не одного объекта то устанавливаем пустое хнанение иначе пост уйдет без objects и нельзя будет удалить последний объект
  if(!settings.data.objects) {
    settings.data.objects = [];
  }
  
  if (id !== undefined) {
    return await api
      .update(settings)
      .then(({ data }) => {        
        handleSuccess(data);        
      })
      .catch(({ response }) => handleError(response))
  }

  await api
    .create(settings)
    .then(({ data }) => {
      //астоматически создается 1 зал для того что бы корректно данные ложидись в операционную таблицу
      api.create({model: "hall", data : {        
              "numberOfFloors": 1,
              "conditioner": "Не выбрано",
              "tv": "Не выбрано",
               "idRestaurants": data.id,
              "title": "Не заполнено",
              "boarding": 1,
              "fit": 100,
              "comment1": "Зал создан автоматически, требуется заполнить данные",
      }})      
      handleSuccess(data);
    })
    .catch(({ response }) => handleError(response))

  async function handleSuccess(data) {
    exportToGoogleSheets(data);      
    alert(id ? 'Данные обновлены' : 'Данные сохранены')

    if (onSave) {
      onSave(data)
      /** Возвращает к редактированию после сохранения */
      window.history.back();
    }
  }

  async function exportToGoogleSheets(restaurantD) {
      
      const hallResponse = await api.getAllHallsRelationRestaurants({ model: MODELS.HALL.VALUE, id: restaurantD.id });
      const hallData = await hallResponse.data;
      const addRestaurants = await hallData.map(i => i.restaurant = restaurantD);
      console.log(hallData);
      startExporsHalls(hallData, postToGoogleDheets);
  }

  function handleError({ data }) {
    if (onError) {
      onError(data)
    }
  }
}
