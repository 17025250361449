import { configureStore } from '@reduxjs/toolkit'
import { userReducer, managerReducer, searchRestaurantsReducer, mapWidget, popupRestorantsSliceReduser} from './reducers'

export default configureStore({
  reducer: {
    user: userReducer,
    manager: managerReducer,
    searchRestaurants: searchRestaurantsReducer,
    widgetMap: mapWidget,
    popupRestorantsSlice: popupRestorantsSliceReduser
    
  },
})
