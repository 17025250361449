import styles from "./ImputOptionRestaurants.module.css";
import { Button } from "antd";

export default function ImputOptionRestaurants({
  data,
  handle,
  setValueInput,
  type,
  setCoord,
  clear,
}) {
  function onHandle() {
    const { onHandle } = handle();
    onHandle(data.id, type, setCoord, clear);

    setValueInput({ data: data.title, type: type });
  }

  return (
    <Button type="dashed" className={styles.wrapper} onClick={() => onHandle()}>
      {data.title}
    </Button>
  );
}
