let URL = process.env.REACT_APP_GOOGLE_SHEETS_UPDATE_HALLS;

async function postToGoogleDheets(postData, action = "post") {

  console.log(postData, action);

  for (const element of postData) {
    var formData = new FormData(); 
    // так определяем это пост или удаление
    formData.append("action", action);
    for (var key in element) {
      formData.append(key, element[key]);
    }

    
  
    try {
      const response = await fetch(`${URL}`, {
        method: "POST",    
        body: formData,
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }

    // try {
    //   const response = await fetch("https://script.google.com/macros/s/AKfycbzPcmXtZSeu3ppIUcsAq8FbCnN7PsNZCCQ8Duh8jeh8bETCrtus6T3t20shBZek9U-J/exec", {
    //     method: "POST",    
    //     body: formData,
    //   });
    //   const data = await response.json();
    //   console.log(data);
    // } catch (error) {
    //   console.error(error);
    // }
  }
}

export default postToGoogleDheets;
