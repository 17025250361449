import { useSearchOnBD } from "shared";
import map from "shared/MapVKSingleton/MapVKSingleton";

const handleRestaurants = () => {
  
  const onHandle = (id, type, setCoord, clear) => {
    
    const { getRestoranById } = useSearchOnBD();

    function centrMap(arrLatLon) {
      map.centerMapOnCoordinates(arrLatLon);
    }

    function index(data) {
      let arrLatLon = data.point.coordinates;
      centrMap([arrLatLon[1], arrLatLon[0]]);
      setCoord({type: type , data: [arrLatLon[1], arrLatLon[0]] })
      clear({type: type});
    }

    getRestoranById(id, (data) => index(data));
  };

  return { onHandle };
};

export default handleRestaurants;
