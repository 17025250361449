import React from 'react'
import { Button, Typography, Row, Col } from "antd";

import { OBJECT_TYPES } from 'constants'

export default function Show({ visibleObjects, onFilter }) {
  const checkActive = (key) => visibleObjects.indexOf(key) != -1

  return (    
    <Row>
      <Col>
      <Typography.Paragraph level={4}>Скройте или отобразите объекты на карте</Typography.Paragraph> </Col>      
      <Col>
        {Object.keys(OBJECT_TYPES).map((KEY) => (
          <Button size="small"
            style={{marginRight: '3px', marginBottom: '3px'}}
            key={KEY}
            type={`${checkActive(KEY) ? "primary" : "dashed"}`}            
            onClick={() => onFilter(checkActive, KEY)}          >
            {OBJECT_TYPES[KEY].PLURAL_VALUE}
          </Button>
        ))}</Col>      
      </Row>    
  )
}
