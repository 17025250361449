import { useState } from "react";
import { useApiVK, useSearchOnBD } from "shared";
import { useDispatch } from "react-redux";
import { setRestaurants } from "store";
import { setSuggest } from "store";
import { setPlaces } from "store";
import { setInput } from "store";

const useHandle = () => {
  const dispatch = useDispatch();

  const { suggestVK, searchPlacesInterest } = useApiVK();
  const { getRestaurants } = useSearchOnBD();

  const [typingTimeout, setTypingTimeout] = useState(null);

  const onInput = (value, type) => {
    dispatch(setInput({ type: type, data: value }));

    clearTimeout(typingTimeout);

    const timeout = setTimeout(() => {
      getRestaurants(value, (data) => {
        dispatch(setRestaurants({ type, data }));
      });

      suggestVK(value, (data) => {
        dispatch(setSuggest({ type, data }));
      });

      searchPlacesInterest(value, (data) => {
        dispatch(setPlaces({ type, data }));
      });
    }, 1000); // Ждем 1 секунду после последнего ввода

    setTypingTimeout(timeout);
  };

  return { onInput };
};

export default useHandle;
