import React, { useEffect, useState } from "react";
import { Button, Space, Row, Col, Typography } from "antd";
import { ManagerOfferContext } from "context";
import { RESTAURANTS_PRIORITY } from "constants";
import handlerAddFilter from "../utils/handlerAddFilter";

export default function FilterForPriopity() {
  const { setDataForFilterReataurants } = React.useContext(ManagerOfferContext);
  const [dataButton, setDataButton] = useState(RESTAURANTS_PRIORITY);

  function handleStatusButton(key) {
    setDataButton((prevState) => {
      const updatedStatus = { ...prevState };
      updatedStatus[key].DEFAULT = !updatedStatus[key].DEFAULT;
      return updatedStatus;
    });
  }

  useEffect(() => {
    const defaultStatuses = Object.keys(dataButton)
      .filter((key) => dataButton[key].DEFAULT)
      .map((key) => dataButton[key].SINGLE_VALUE);

    handlerAddFilter(defaultStatuses, setDataForFilterReataurants, "priority");
  }, [dataButton]);

  return (
    <Row>
      <Col>
        <Typography.Paragraph level={4}>
          Фильтрация по приоритетам:
        </Typography.Paragraph>{" "}
      </Col>
      <Col>
        {Object.keys(dataButton).map((KEY) => (
          <Button
            size="small"
            style={{ marginRight: "3px", marginBottom: "3px" }}
            key={KEY}
            type={`${dataButton[KEY].DEFAULT ? "primary" : "dashed"}`}
            onClick={() => handleStatusButton(KEY)}
          >
            {dataButton[KEY].PLURAL_VALUE}
          </Button>
        ))}
      </Col>
    </Row>
  );
}
