import React from 'react'
import axios from './axios'
import auth from './auth'
import files from './files'

import { MODELS } from 'constants'

class Api {
  constructor() {
    this.apiUrl = {
      user: MODELS.USER.API_URL,
      restaurant: MODELS.RESTAURANT.API_URL,
      object: MODELS.OBJECT.API_URL,
      location: MODELS.LOCATION.API_URL,
      menu: MODELS.MENU.API_URL,
      settings: '/settings',
      data: '/data',
      hall: MODELS.HALL.API_URL
    }

    this.auth = auth
    this.files = files
  }

  #token = localStorage.getItem('token')
  

  async create({ model, data }) {
    const token = this.#token

    return await axios.post(this.apiUrl[model], data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  async update({ model, id, data, params }) {
    const token = this.#token

    return await axios.put(this.apiUrl[model] + '/' + (id ? id : ''), data, {
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  async publicate({ model, id, data }) {
    const token = this.#token

    return await axios.put(this.apiUrl[model] + '/' + id + '/publicate', data, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  async getAll({ model, params = {} }) {    
    //const token = this.#token
   const token = localStorage.getItem('token');
    
    return await axios.get(this.apiUrl[model], {
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  async getDataAfterFilter({ model, params, data }) {    
    const token = this.#token    
    return await axios.post(this.apiUrl[model] + '/filter', data, {
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },      
    })
  }



  async getOne({ model, id, params = {} }) {    
    const token = this.#token
    return await axios.get(this.apiUrl[model] + '/' + id, {
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  async getAllHallsRelationRestaurants({ model, id, params = {} }) {    
    const token = this.#token
    return await axios.get(this.apiUrl[model] + '/relation/' + id, {
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }
  // запрашиваем объекты по радиусу
  async getObjectsWithRadius({ model, radius, params = {}, lat, lon }) {    
    const token = this.#token
    return await axios.get(this.apiUrl[model] + '/radius/' + '?' + 'radius=' + radius + "&lat=" + lat + "&lon=" + lon , {
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  // обновляем данные в Goole Sheets
// async updateGoogleSheets({model, params = {},}) {
//   const token = this.#token
//     return await axios.get(this.apiUrl[model] + '/update', {
//       params,
//       headers: {
//         Authorization: 'Bearer ' + token,
//       },
//     })
// }


  async delete({ model, id }) {
    const token = this.#token

    return await axios
      .delete(this.apiUrl[model] + '/' + id, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .catch(({ response }) => alert(response.data.message))
  }
}

export default new Api()
