import styles from "./MapServisePages.module.scss";
import React, { Suspense, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { USER_ROLES } from "constants";
import { getObjKeyName } from "utils";
import { MapWidget } from "widgets/MapWidget";

export default function MapServisePages() {
  const { setRoles, user } = useOutletContext();
  setRoles([
    getObjKeyName(() => USER_ROLES.ADMIN),
    getObjKeyName(() => USER_ROLES.MANAGER),
    getObjKeyName(() => USER_ROLES.REDAKTOR),
  ]);  

  function Loading() {
    return <h2>🌀 Загрузка...</h2>;
  }

  return (
    <Suspense fallback={<Loading />}>
      <MapWidget />
    </Suspense>
  );
}
