import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";

import { ShareAltOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { ManagerOfferContext } from "context";
import SearchInput from "./components/SearchInput";
import getPointRout from "./components/getPointRout";
//import transferringFirstValueToRouter from "./components/transferringFirstValueToRouter";

export default function ServisMapVK() {
  //const VKMAP = process.env.REACT_APP_VKMAPS_API_KEY;

  const { setDataPointForRoutVKMap, dataPointForRoutVKMap, setSelectPosition, dataForRoutDistanceTime, setDataForRoutDistanceTime } =
    React.useContext(ManagerOfferContext);
  const [isVisibleRoutBuild, setVisibleRoutBuild] = useState(false);
  const [routData, setRoutData] = useState({ first: null, second: null });
 //const {valueFirstInput} = transferringFirstValueToRouter;


 
 // значение для копирования главнйо строки в первую строку маршрута
 const [valueFirstInput, setValueFirstInput] = useState('')

  useEffect(() => {   
    // если есть и первые координаты и вторые то производим запрос
    if (routData.first && routData.second) {
      
      getPointRout(
        routData.first.lat,
        routData.first.lon,
        routData.second.lat,
        routData.second.lon,
        setDataPointForRoutVKMap,
        //получаем данные о маршруте        
        setDataForRoutDistanceTime
      );
    }
  }, [routData]);

  return (
    <div
      style={{
        border: "solid 1px #d9d9d9",
        padding: "5px",
        borderRadius: "5px",
        marginTop: "5px",
        backgroundColor: "#f2f3f9",
      }}
    >

<Typography.Paragraph level={4}>
        Поиск на карте:
      </Typography.Paragraph>
      <Row>
        <SearchInput
          placeholder="Поиск мест, адресов, ресторанов"
          style={{
            width: "100%",
            marginRight: "0px",
          }}
          type="one"
          setRoutData={setRoutData}
          setSelectPosition={setSelectPosition}
          setDataPointForRoutVKMap={setDataPointForRoutVKMap}
          //valueFirstInput={valueFirstInput}
          valueFirstInput={valueFirstInput}
           setValueFirstInput={setValueFirstInput}
          
        />        
      </Row>

      <Row justify="space-between">
        <Button
         style={{
          width: "45%",
          marginTop: "5px",
          marginBottom: "5px",
        }}
          type="primary"
          onClick={() => {
            setVisibleRoutBuild(false);
            // обнуляем значения для построения маршрута
            setDataPointForRoutVKMap([]);
            // и убираем точку поиска
            setSelectPosition(null);
            setRoutData({ first: null, second: null });
          }}
          
          //icon={<ShareAltOutlined />}
          size="default"
        >Сбросить</Button>
        <Button
         style={{
          width: "45%",
          marginTop: "5px",
          marginBottom: "5px",
        }}
          type="primary"
          onClick={() => {
            setVisibleRoutBuild(!isVisibleRoutBuild);
            // обнуляем значения для построения маршрута
            //setDataPointForRoutVKMap([]);
            // и убираем точку поиска
            //setSelectPosition(null);
            //setRoutData({ first: null, second: null });
          }}
          
          
          size="default"
        >Маршрут</Button>
        
          
          </Row>
      
        <>
          

        {isVisibleRoutBuild && <Row justify="space-between" align="middle">
            <Col flex="100%">
              <SearchInput
                placeholder="Построить маршрут - Откуда"
                style={{
                  width: "100%",
                }}
                type="first"
                setRoutData={setRoutData}
                setSelectPosition={setSelectPosition}
                valueFirstInput={valueFirstInput}
           setValueFirstInput={setValueFirstInput}
              />

              <SearchInput
                placeholder="Построить маршрут - Куда"
                style={{
                  width: "100%",
                  marginTop: 5,
                }}
                type="second"
                setRoutData={setRoutData}
                setSelectPosition={setSelectPosition}
                valueFirstInput={valueFirstInput}
                setValueFirstInput={setValueFirstInput}
              />
            </Col>
          </Row>}  
        </>

      {dataPointForRoutVKMap.length > 0 && <div> Время: {Math.ceil(dataForRoutDistanceTime.time / 60)} мин, Расстояние: {Math.ceil(dataForRoutDistanceTime.distanse)} км </div>}  
     
    </div>
  );
}
