//import styles from "./Filter.module.scss";

import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import { TimePicker, Typography } from "antd";
import dayjs from "dayjs";
import { ManagerOfferContext } from "context";

export default function FilterForDate() {
  // Добавления выбранных объектов
  //const [selectObjects, setSelectObjects] = React.useState([]);

  const { setDataForFilterReataurants } = React.useContext(ManagerOfferContext);
  const [timValue, setTimeValue] = useState({});
  const [valueFormTime, setValueFormTime] = useState(null);
  const [isEnabled, steEnabled] = useState({
    mon: false,
    tue: false,
    web: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });

  // при изменении в фильтре даты или диапазоне времени происходит пересчет данных для фильтрации
  useEffect(() => {
    // формирую значения для вставки в фильтр
    const dataTimeDay = {
      mondayStart: isEnabled.mon ? timValue.Start : false,
      mondayEnd: isEnabled.mon ? timValue.End : false,
      tuesdayStart: isEnabled.tue ? timValue.Start : false,
      tuesdayEnd: isEnabled.tue ? timValue.End : false,
      wednesdayStart: isEnabled.web ? timValue.Start : false,
      wednesdayEnd: isEnabled.web ? timValue.End : false,
      thursdayStart: isEnabled.thu ? timValue.Start : false,
      thursdayEnd: isEnabled.thu ? timValue.End : false,
      fridayStart: isEnabled.fri ? timValue.Start : false,
      fridayEnd: isEnabled.fri ? timValue.End : false,
      saturdayStart: isEnabled.sat ? timValue.Start : false,
      saturdayEnd: isEnabled.sat ? timValue.End : false,
      sundayStart: isEnabled.sun ? timValue.Start : false,
      sundayEnd: isEnabled.sun ? timValue.End : false,
    };
    // убираю значения false
    const filteredData = Object.fromEntries(
      Object.entries(dataTimeDay).filter(
        ([key, value]) => value !== false && value !== undefined
      )
    );
    
    if (filteredData != {}) {
      // удаляю все старые значения со временем и записываю заново
      setDataForFilterReataurants((prevState) => {
        const newState = { ...prevState };

        for (let key in newState) {
          if (key.endsWith("start") || key.endsWith("end")) {
            delete obj[key];
          }
        }

        return { ...newState, ...filteredData };
      });
    }
  }, [isEnabled, timValue]);

  function handleTimePicer(t) {
    setValueFormTime(t);    
    const time = {
      Start: t[0].$H + t[0].$m / 100,
      End: t[1].$H + t[1].$m / 100,
    };
    setTimeValue(time);
  }

  function handleButtonDay(key) {
    steEnabled((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  }

  function ButtonForDay({ day, name }) {
    return (
      <Button
        size="small"
        type={`${isEnabled[day] ? "primary" : "dashed"}`}
        onClick={() => handleButtonDay(day)}
      >
        {name}
      </Button>
    );
  }

  const GroupButton = () => (
    <Space wrap>
      <ButtonForDay day="mon" name="ПН"></ButtonForDay>
      <ButtonForDay day="tue" name="ВТ"></ButtonForDay>
      <ButtonForDay day="web" name="СР"></ButtonForDay>
      <ButtonForDay day="thu" name="ЧТ"></ButtonForDay>
      <ButtonForDay day="fri" name="ПТ"></ButtonForDay>
      <ButtonForDay day="sat" name="СБ"></ButtonForDay>
      <ButtonForDay day="sun" name="ВС"></ButtonForDay>
    </Space>
  );

  const Time = () => (
    <TimePicker.RangePicker
      placeholder={["начало", "конец"]}
      defaultValue={dayjs("12:08", "HH:mm")}
      value={valueFormTime}
      format={"HH:mm"}
      onChange={handleTimePicer}
    />
  );
  return (
    <React.Fragment>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Typography.Paragraph level={4}>
          Выберите диапазон по количество человек:
        </Typography.Paragraph>
        <GroupButton />
        <Time />
      </Space>
    </React.Fragment>
  );
}
