import { api } from "utils";

export default async function handleSave({
  data,
  model,
  onSave,
  onError,
  id = undefined,
}) {
  // получаем все залы которые есть в ресторане
  const settings = await data.halls?.map((i) => {    
      return { model: "hall", data: i, id: i.id };
  });

  // отправляем на поочередное сохранение залов
  settings?.map(async (item) => {

    // если у зала есть свой id отправляем на обновление
    if (item.data.id !== "") {
      return await api
        .update(item)
        .then(({ data }) => handleSuccess(data))
        .catch(({ response }) => handleError(response));
    }

    // если у ресторана нет id своего, то создаем новый
    // добавляем id сомого ресторана
    item.data.idRestaurants = id;

    await api
      .create(item)
      .then(({ data }) => handleSuccess(data))
      .catch(({ response }) => handleError(response));

    function handleSuccess(data) {
      //alert(id ? "Данные обновлены" : "Данные сохранены");
      // if (onSave) {
      //   onSave(data)
      // }
    }

    function handleError({ data }) {      
      if (onError) {
        onError(data);
      }
    }
  });
}
