import { SerchSelector } from "entities/SerchSelector";


//import { UseSerchRestaurantsContecst } from "context";
import { useEffect, useState } from "react";
//import React = require("react");
import React from "react";
import useHandle from "../hooks/useHandle";
import handleRestaurants from "../hooks/handleRestaurants";
import handleSuggest from "../hooks/handleSuggest";
import handlePlaces from "../hooks/handlePlaces";
//import { useDispatch, useSelector } from 'react-redux';
/** Данная фича ищет по ресторанам, по объектам карты, устанавливает слушатель на объекты
 *
 *
 */

export default function UseSerchRestaurants({type}) {
  // записываем результат поиска
  /**
   *
   */

  const { onInput } = useHandle();

  //const data = useSelector(state => state.data);

  //<UseSerchRestaurantsContecst.Provider value={{setSerch}} >

  return (
    <SerchSelector
      onInputlist={onInput}     
      handleRestaurants={handleRestaurants}      
      handleSuggest={handleSuggest}
      handlePlaces={handlePlaces}
      type={type}
    ></SerchSelector>
  );

  // </UseSerchRestaurantsContecst.Provider>
}
