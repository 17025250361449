const useHandle = (onInput, type) => {

    const handle = (e) => {        
        onInput( e.target.value, type )
    }
    
    return {handle}
}

export default useHandle;
