import { useEffect, useState } from "react";

export default function useApiMapManager(searched, setSearch, type) {
  const [buildRestaurants, setBuildRestaurants] = useState([]);
  const [isUseInputActiv, setUseInputActiv] = useState(false);

  const checkActivInput = (value) => {
    setUseInputActiv(value == type);
  };

  const serchForRestaurants = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    const restaurantSerch = searched?.map((item) => {
      return {
        label: item.text,
        value: item.text,
        id: item.id,
        point: item.point.coordinates,
      };
    });

    setBuildRestaurants(restaurantSerch);
  }, [searched]);

  return {
    serchForRestaurants,
    buildRestaurants,
    setBuildRestaurants,
    checkActivInput,
    isUseInputActiv,
    setUseInputActiv,
  };
}
