import { OptionGroup } from "entities/InputGroup";
import React, { useState } from "react";
import { ImputSerch } from "shared/ImputSerch";
import styles from "./SerchSelector.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setInput } from "store";
import { setCoordinates } from "store";
import { clearLists } from "store";
import { clearInput } from "store";
import map from "shared/MapVKSingleton/MapVKSingleton";
import { Typography } from "antd";
/** что то вводят
 * отрисовывается список
 * при клике происходит выбор *
 * что конкретно не знаем
 */
export default function SerchSelector({
  onInputlist,
  handleRestaurants,
  handleSuggest,
  handlePlaces,
  type,
}) {
  const widgetMapData = useSelector((state) => state.widgetMap);
  const { restaurants, suggest, places, input } = widgetMapData[type];
  const dispatch = useDispatch();

  function setValueInput({ data, type }) {
    dispatch(setInput({ data, type }));
  }

  function setCoord({ data, type }) {
    dispatch(setCoordinates({ data, type }));
  }

  function clearDataList({ type }) {
    dispatch(clearLists({ type }));
  }

  function clearInputAndCoords({ type }) {
     dispatch(clearInput({ type }));
     map.deleteRout();
     map.deleteMarkerFotType(type);
  }

  function RestaurantsData() {
    if (restaurants.length > 0) {
      return (
        <div className={styles.list}>
          <Typography.Text strong>Рестораны</Typography.Text>
          {restaurants?.map((i) => (
            <OptionGroup.Restaurants
              key={i.id}
              data={i}
              handle={handleRestaurants}
              setValueInput={setValueInput}
              setCoord={setCoord}
              type={type}
              clear={clearDataList}
            />
          ))}
        </div>
      );
    }
  }

  function SuggestData() {
    if (suggest.length > 0) {
      return (
        <div className={styles.list}>
          <Typography.Text strong>Подсказчик адреса</Typography.Text>
          {suggest?.map((i) => (
            <OptionGroup.Suggest
              key={i.key}
              data={i}
              handle={handleSuggest}
              setValueInput={setValueInput}
              setCoord={setCoord}
              type={type}
              clear={clearDataList}
              
            />
          ))}
        </div>
      );
    }
  }

  function PlacestData() {
    if (places.length > 0) {
      return (
        <div className={styles.list}>
          <Typography.Text strong>Поиск мест интереса</Typography.Text>
          {places?.map((i) => (
            <OptionGroup.Places
              key={i.key}
              data={i}
              handle={handlePlaces}
              setValueInput={setValueInput}
              setCoord={setCoord}
              type={type}
              clear={clearDataList}
              input={input}
            />
          ))}
        </div>
      );
    }
  }

  return (
    <div className={styles.lists}>
      <div className={styles.input__container}>        
        <ImputSerch
          onInputlist={onInputlist}
          valueInput={input}
          type={type}
        ></ImputSerch>
        <button onClick={()=> {
          clearDataList({ type });
          clearInputAndCoords({type});
        } } className={styles.input__clear}>x</button>
      </div>

      <RestaurantsData />
      <SuggestData />
      <PlacestData />
    </div>
  );
}
