
import React, { useEffect, useState } from 'react';
import map from 'shared/MapVKSingleton/MapVKSingleton';
export default React.memo(function MapVK({markers}) {
  useEffect(() => {    
    map.initializeMap(markers);
    return () => {
      map.removeMap();
    };
  }, [markers]);

  // Рендер карты с загруженными данными
  return <div id="map" style={{ width: '100%', height: '90vh' }} />;
});


