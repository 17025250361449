import { useApiVK } from "shared";
import map from "shared/MapVKSingleton/MapVKSingleton";
import { useDispatch } from "react-redux";
import { setInfoAboutRout } from "store";

const useHandle = () => {
    const dispatch = useDispatch();
    const {getPointRout} = useApiVK();

    const onBuild = (first, second) => {
        
        getPointRout( first, second, (shape, infoForRout) => {
            map.buildRoutOnMap(shape);
            dispatch(setInfoAboutRout(infoForRout))
        });
    }

    return {onBuild}

}

export default useHandle;

