export const MODELS = {
  LOCATION: {
    API_URL: '/locations',
    VALUE: 'location',
  },
  MENU: {
    API_URL: '/menus',
    VALUE: 'menu',
  },
  RESTAURANT: {
    API_URL: '/restaurants',
    VALUE: 'restaurant',
  },
  OBJECT: {
    API_URL: '/objects',
    VALUE: 'object',
  },
  USER: {
    API_URL: '/users',
    VALUE: 'user',
  },
  HALL: {
    API_URL: '/halls',
    VALUE: 'hall',
  },
}

export const USER_ROLES = {
  ADMIN: {
    SINGLE_VALUE: 'Админ',
    PLURAL_VALUE: 'Админы',
  },
  MANAGER: {
    SINGLE_VALUE: 'Менеджер',
    PLURAL_VALUE: 'Менеджеры',
  },
  REDAKTOR: {
    SINGLE_VALUE: 'Редактор',
    PLURAL_VALUE: 'Редакторы',
  },
}

export const OBJECT_TYPES = {
  CEMETERY: {
    SINGLE_VALUE: 'Кладбище',
    PLURAL_VALUE: 'Кладбища',
  },
  CREMATORY: {
    SINGLE_VALUE: 'Крематорий',
    PLURAL_VALUE: 'Крематории',
  },
  MORGUE: {
    SINGLE_VALUE: 'Морг',
    PLURAL_VALUE: 'Морги',
  },
  METRO: {
    SINGLE_VALUE: 'Метро',
    PLURAL_VALUE: 'Метро',
    WC_ATTRIBUTE: 'pa_metro',
  },
  DISTRICT: {
    SINGLE_VALUE: 'Район',
    PLURAL_VALUE: 'Районы',
    WC_ATTRIBUTE: 'pa_rajon',
   },
   ZONE: {
    SINGLE_VALUE: 'Округ',
    PLURAL_VALUE: 'Округа',
    WC_ATTRIBUTE: 'pa_okrug',
   },
  //  OBJECT: {
  //   SINGLE_VALUE: 'Объект',
  //   PLURAL_VALUE: 'Объекты',
  //  },
   RESTAURANT: {
    SINGLE_VALUE: 'Ресторан',
    PLURAL_VALUE: 'Рестораны',
   }
}

export const RESTAURANTS_STATUS = { 
  AGREEMENT : {
    SINGLE_VALUE: 'Работаем по согласованию',
    PLURAL_VALUE: 'Работаем по согласованию',
    DEFAULT: false,
  },

  NOT_WORK : {
    SINGLE_VALUE: 'Не работаем',
    PLURAL_VALUE: 'Не работаем',
    DEFAULT: false,
  },

  CLOSED : {
    SINGLE_VALUE: 'Закрыт',
    PLURAL_VALUE: 'Закрыт',
    DEFAULT: false,
  },

  WORK : {
    SINGLE_VALUE: 'Работаем',
    PLURAL_VALUE: 'Работаем',
    DEFAULT: true,
  },
}

export const RESTAURANTS_PRIORITY = { 
  FIRST : {
    SINGLE_VALUE: '1',
    PLURAL_VALUE: 1,
    DEFAULT: true,
  },

  SECOND : {
    SINGLE_VALUE: '2',
    PLURAL_VALUE: 2,
    DEFAULT: true,
  },

  THIRD : {
    SINGLE_VALUE: '3',
    PLURAL_VALUE: 3,
    DEFAULT: true,
  },

  FOURTH : {
    SINGLE_VALUE: '4',
    PLURAL_VALUE: 4,
    DEFAULT: true,
  },

  FIFTH : {
    SINGLE_VALUE: '5',
    PLURAL_VALUE: 5,
    DEFAULT: true,
  },

}

