import React, { useEffect, useState } from "react";
import style from "./MapWidget.module.css";
import initialRestaurants from "../hooks/initialRestaurants";
import { MapVK } from "widgets/MapVK";
import { ServisSearcForVK } from "widgets/ServisSearcForVK";
import { useSelector } from "react-redux";
import { RestaurantModal } from "pages/manager/WorkspacePage/components";
import { useDispatch } from "react-redux";
import { openPopup } from "store";
import { MapVkLegend } from "shared/MapVkLegend";
export default function MapWidget() {
  const [restaurantData, setRestaurantData] = useState(null);
  const { loadRestaurantData } = initialRestaurants();
  const modalFor = useSelector((state) => state.popupRestorantsSlice.value);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const data = await loadRestaurantData;
      setRestaurantData(data);
    };

    fetchData();
  }, [loadRestaurantData]);

  if (!restaurantData) {
    return <div>Loading...</div>; // Показываем сообщение о загрузке, пока данные не загружены
  }

  // Рендер карты с загруженными данными
  return (
    <>
      <div className={style.search}>
        <ServisSearcForVK />
      </div>
      <div className={style.legend}>
       <MapVkLegend/>
      </div>
      <MapVK markers={loadRestaurantData} />
      {modalFor && (
        <RestaurantModal
          id={modalFor}
          onClose={() => dispatch(openPopup(null))}
        />
      )}
    </>
  );
}
