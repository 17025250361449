import { useSelector } from "react-redux";


export default function InfoAboutRout() {
 
  const infoRout = useSelector((state) => state.widgetMap.infoRout);

  const getText = () => {
    if (infoRout.time && infoRout.distanse) {
      return (
        <div>          
          Время: {Math.ceil(infoRout.time / 60)} мин, Расстояние:
          {Math.ceil(infoRout.distanse)} км
        </div>
      );
    } else {
      return <></>;
    }
  };

  return <>{getText()}</>;
}
