import React, { useEffect, useState } from "react";
import { ManagerOfferContext } from "context";
import { useSearch } from "./hooks";

import { OBJECT_TYPES } from "constants";

import { Select, Space } from "antd";

function SelectFilterObjects({ type, reset }) {
  const { setFilterRestaurants } = React.useContext(ManagerOfferContext);

  //стейты поиска
  const [searchValue, setSearchValue] = React.useState("");
  const objects = useSearch(searchValue, type);
  const buildName = `${OBJECT_TYPES[type].SINGLE_VALUE}`;

  //сброс фильтров только по объектам ресторанов
  useEffect(() => {
    setFilterRestaurants([]);
  }, [reset]);

  // установка значений в массив объектов ресторанов
  const handleChange = (value) => {
    const valueObjects = objects.filter((item) => item.id === value);
    setFilterRestaurants((prevData) => {
      // если такой объект уже есть его не добавляем
      const isPush = prevData.find((i) => i.id == valueObjects[0].id);
      if (isPush) {
        return prevData;
      } else {
        return prevData.concat(valueObjects);
      }
    });

    setSearchValue("");
  };
  return (
    <Select
      showSearch
      optionFilterProp="children"
      style={{
        width: "100%",
      }}
      // placeholder={buildName}      
      onSearch={setSearchValue}
      optionLabelProp="label"
      maxTagCount="2"
      value={buildName}
      onChange={handleChange}
      filterOption={(input, option) => objects}
      options={objects?.map((i) => ({
        value: i.id,
        key: i.id,
        label: i.title,
      }))}
    ></Select>
  );
}

export default SelectFilterObjects;
