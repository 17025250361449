import React from 'react'

import { api } from 'utils'
import { MODELS } from 'constants'

export default function useGetData(id, added) {
  const [data, setData] = React.useState()
  const [menus, setMenus] = React.useState([])

/** Старый запрос до разделения объектов на ресторан и зал */
  // React.useEffect(() => {
  //   api
  //     .getOne({
  //       model: MODELS.RESTAURANT.VALUE,
  //       id,
  //     })
  //     .then(({ data }) => {
  //       setData(data)
  //     })
  // }, [])

   /** Инициализация данных о ресторане
   * 1. Получаю данные по ресторану который начали редактировать
   * 2. По ID этого ресторана делаю запрос в БД и получаю залы связанные с рестораном
   * 3. устанавливаю значения вида [{},{},{}] в ключ halls для удобной отрисовке в карточке
   * !!! при нажатии на "сохранить" сохраняются и обновляются все данные по всем залам и ресторану 
   */
   React.useEffect(() => {    
    if (id) {
      const fetchData = async () => {
        const restaurantResponse = await api.getOne({ model: MODELS.RESTAURANT.VALUE, id });
        const hallResponse = await api.getAllHallsRelationRestaurants({ model: MODELS.HALL.VALUE, id });  
        const restaurantData = restaurantResponse.data;
        const hallData = hallResponse.data;  
        const updatedInitial = { ...restaurantData, halls: hallData };  
        setData(updatedInitial);
      }  
      fetchData();
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      api
        .getAll({
          model: MODELS.MENU.VALUE,
          params: { where: { id: data.menus.map(({ id }) => id) } },
        })
        .then(({ data: d }) =>
          setMenus(
            d.map((menu) => {
              return {
                ...menu,
                ...data.menus.find(({ id }) => id === menu.id),
                added: added
                  ? added.menus.find(({ id }) => menu.id === id)
                  : true,
              }
            })
          )
        )
    }
  }, [data])

  return [data, menus, setMenus]
}
