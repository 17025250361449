export default function buildCategory(metro, zali, kanoni, kogda, stoimost, okrug, crematory, morg, raion, type ) {
//   let metro = 'Медведково, Бибирево';
//   let zali = '10 человек, 15 человек, 20 человек';
//   let kanoni = 'Традиционные, Православные';
//   let kogda = 'сегодня, завтра, в день, 9 дней, 40 дней, год, годовщина, день похорон';
//   let stoimost = '1000 рублей, 3000 рублей';
//   let okrug = 'ЦАО, CАО';  
//   let crematory = 'Крематорий 1, Крематорий 2';
//   let morg = 'Морг 1, Морг 2';
//   let raion = 'Басманный, Арбат';
//   let type = 'Кафе'; 

  let resule = ["Поминки"];

  function pustArr(type, data) {
 data.split(',').map(k => {
    resule.push(`Поминки > ${type} > ${k}`); 
  })
  }

  

  
let categiryType = () => {  
   resule.push(`Поминки > ${type}`);  
      pustArr(type, kanoni);   
      pustArr(type, okrug);  
      pustArr(type, metro);  
      pustArr(type, raion);  
      pustArr(type, kogda);  
      pustArr(type, zali);  
      pustArr(type, stoimost); 
  
} 
categiryType();
  
let kanoniType = kanoni.split(', ').map(i => {
   resule.push(`Поминки > ${i}`); 

  
  zali.split(',').map(k => {
    resule.push(`Поминки > ${i} > ${k}`); 
  })
   kogda.split(',').map(y => {
    resule.push(`Поминки > ${i} > ${y}`); 
  })
   stoimost.split(',').map(h => {
    resule.push(`Поминки > ${i} > ${h}`); 
  }) 
 
});


pustArr('Сколько', zali);

let kogdaType = kogda.split(',').map(i => {

   resule.push(`Поминки > ${i}`); 
  
  pustArr(i, zali);
  pustArr(i, stoimost);
})

let okrugType = okrug.split(',').map(i => {
   resule.push(`Поминки > ${i}`); 
pustArr(i,kogda);
pustArr(i,zali);
pustArr(i,stoimost);
pustArr(i,kanoni);
} );

let metroType = metro.split(',').map(i => {
   resule.push(`Поминки > ${i}`); 
pustArr(i,kogda);
pustArr(i,zali);
pustArr(i,stoimost);
pustArr(i,kanoni);
} )


let raionType = raion.split(',').map(i => {
   resule.push(`Поминки > ${i}`); 
  pustArr(i,kogda);
pustArr(i,zali);
pustArr(i,stoimost);
pustArr(i,kanoni);
} )

resule.push(`Поминки > Выездные`);  
let viezdnieKogda = kogda.split(',').map(i => {
  
   pustArr('Выездные',i);
})
let viezdnieZali = zali.split(',').map(i => {
   
   pustArr('Выездные',i);
})
let viezdnieStoimost = stoimost.split(',').map(i => {
   
   pustArr('Выездные',i);
})
let viezdnieKanoni = kanoni.split(',').map(i => {
    
   pustArr('Выездные',i);
})

////////////////////////////////////////////////////
resule.push(`Поминки > С доставкой`); 
let sdostavkoiKogda = kogda.split(',').map(i => {
   
   pustArr('С доставкой', i);
})
let sdostavkoiZali = zali.split(',').map(i => {
    
   pustArr('С доставкой', i); 
})
let sdostavkoiStoimost = stoimost.split(',').map(i => {
   
   pustArr('С доставкой', i); 
})
let sdostavkoiKanoni = kanoni.split(',').map(i => {
    
   pustArr('С доставкой', i);
})
resule.push(`Поминки > Крематорий`); 
pustArr('Крематорий', zali);
pustArr('Крематорий', stoimost);
pustArr('Крематорий', okrug);
pustArr('Крематорий', metro);
pustArr('Крематорий', raion);
pustArr('Крематорий', crematory);
resule.push(`Поминки > Морг`); 
pustArr('Морг', zali);
pustArr('Морг', stoimost);
pustArr('Морг', okrug);
pustArr('Морг', metro);
pustArr('Морг', raion);
pustArr('Морг', morg);
pustArr('Морг', 'сегодня, завтра');
pustArr('Морг', kanoni);
resule.push(`Поминки > Прощальный зал`); 
pustArr('Прощальный зал', zali);
pustArr('Прощальный зал', stoimost);
pustArr('Прощальный зал', okrug);
pustArr('Прощальный зал', metro);
pustArr('Прощальный зал', raion);
pustArr('Прощальный зал', 'сегодня, завтра');
pustArr('Прощальный зал', kanoni);
var string = resule.join('| '); // Преобразование массива в строку с разделителем ", "
string = string.replace(/"/g, ''); // Удаление всех кавычек из строки

return string;
}