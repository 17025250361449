
import styles from "./ImputOptionSuggest.module.css"
import { Button } from 'antd';
export default function ImputOptionSuggest ({data, handle, setValueInput, type,  setCoord, clear}) {


    function onHandle() {  
        const {onHandle} = handle();
        onHandle(data.ref, type, setCoord, clear );          
        setValueInput({data:data.label, type: type} );       
     
     }

    return ( <Button type="dashed" className={styles.wrapper} key={data.key} onClick={()=> onHandle()}>{data.label}</Button>)
}