    export default function handlerAddFilter(item, setData, type) {
      // если объект пустой вида object: {} то удаляю этот ключ из поиска
      if (item.length === 0) {
        setData(prevState => {
          const newState = { ...prevState };
          delete newState[type];
          return newState;
        });
      } else {
        setData(prevState => ({
          ...prevState,
          [type]: item
        }));
      }
    }
    