import buildCategory from "./buildCatrgory";

function handleLoadinfForImport (data) {
    let image =
      "http://ce99336-wordpress-eo1hr.tw1.ru/msk/wp-content/uploads/sites/2/2023/10/850031c4a9601bc16e04701b46130576-scaled.jpg ! alt :  ! title : 850031c4a9601bc16e04701b46130576 ! desc :  ! caption :  | http://ce99336-wordpress-eo1hr.tw1.ru/msk/wp-content/uploads/sites/2/2023/10/cropped-architecture-2562861_1920.jpg ! alt :  ! title : cropped-architecture-2562861_1920 ! desc :  ! caption :  | http://ce99336-wordpress-eo1hr.tw1.ru/msk/wp-content/uploads/sites/2/2023/10/img2.akspic.ru-priroda-pustynya-nebo-lug-rastitelnost-4321x2882-1-scaled.jpg ! alt :  ! title : img2.akspic.ru-priroda-pustynya-nebo-lug-rastitelnost-4321x2882 ! desc :  ! caption : ";

      const modDataToExport = data.map((i) => {     
        
        console.log(i)
      let catgory_type = i.restaurant.title.toLowerCase().includes("столовая") ? "Столовая": i.restaurant.title.toLowerCase().includes("кафе") ? "Кафе" : "Ресторан";
      let metro_category = i.restaurant.pa_metro != undefined ? i.restaurant.pa_metro.join(","): "Метро отсутствует"; 
      
      let zali_category =               i.fit <= 15 ? '10 человек' : 
                          (i.fit >= 15 && i.fit < 20) ? "15 человек":
                          (i.fit >= 20 && i.fit < 50) ? "20 человек":
                          (i.fit >= 50 && i.fit < 70) ? "50 человек":
                          (i.fit >= 70 && i.fit <100) ? "70 человек": '100 человек';                           

      let kanoni_category = 'Традиционные, Православные';
      let kogda_category = 'сегодня, завтра, в день, 9 дней, 40 дней, год, годовщина, день похорон';
      let stoimost_category = '1000 рублей, 3000 рублей';
      let rajon_category = i.restaurant.pa_rajon != undefined ? i.restaurant.pa_rajon.join(","): "Район отсутствует";
      let crematory_category = 'Митинский крематорий, Николо-Архангельский, Хованский крематорий'; 
      let morg_category = "Морг Литовский бул 1А стр 1 Москва, Морг Пехотная ул 3 стр 15 Москва, Морг ул Саляма Адиля 2/44с12 Москва,  Морг ул Стромынка 7 корп 10 Москва";
      let okrug_category = i.restaurant.pa_okrug != undefined ? i.restaurant.pa_okrug.join(","): "Округ отсутствует";


     // metro, zali, kanoni, kogda, stoimost, okrug, crematory, morg, raion, type
     
      let pa_metro =  i.restaurant.pa_metro != undefined ? i.restaurant.pa_metro.join("|"): '';
      let pa_rajon =  i.restaurant.pa_rajon != undefined ? i.restaurant.pa_rajon.join("|"): '';
      let pa_okrug =  i.restaurant.pa_okrug != undefined ? i.restaurant.pa_okrug.join("|"): '';  
      
   // преобразовываю id операционки в woocommerce 
    function duildIdForWoocommerce(idOperacionka) {
      let letters = idOperacionka.replace(/[^a-zA-Z]/g, ''); // оставляем только буквы
      let numbers = idOperacionka.replace(/[^0-9]/g, ''); // оставляем только цифры
        switch (letters) {
          case 'msk':
            return `100${numbers}`;    
            break
            case 'spb':
              return `200${numbers}`;    
              break
              case 'mo':
            return `300${numbers}`;    
            break
            case 'nn':
            return `400${numbers}`;    
            break
          default:
            return `777${Math.floor(Math.random() * 900000) + 100000}`;   
            break
        }
    }

    function buildImage(img) {


let dataImage = '';

if(img.length == 0) {
  return image;
}

function getNameImage(pathImage) {  
  
  const image = pathImage.split("/static/images/")[1];
 
  return image;
}

img.forEach(i => {
 

  dataImage += `http://ce99336-wordpress-eo1hr.tw1.ru/wp-content/uploads/2023/10/${getNameImage(i)} ! alt :  ! title : картинка ! desc :  ! caption :  |`
} );

return dataImage;


    }
      const ImportWoocommerse = {
        spz_id: i.restaurant.idFromCompany,
        spz_delivery: i.restaurant.delivery,
        spz_priority: i.restaurant.priority,
        spz_title: i.restaurant.title,
        spz_prepay: i.restaurant.prepay,
        spz_status: i.restaurant.status,
        spz_id_halls: i.id,        
        spz_id_restaurants: i.restaurant.id,
        post_title: `${i.restaurant.cardTitle} ${i.title}`, // название на русском
        post_name: "", //? название на английском - это и есть сама ссылка
        post_parent: "", //?
        ID: duildIdForWoocommerce(i.restaurant.idFromCompany), //! нужно ли конвертировать?
        post_content: "post_content", //?
        post_excerpt: "post_excerpt", //?
        post_status: i.restaurant.isPublished ? "publish" : "pending", // статус публикации woocommerce pending  publish и есть еще один
        post_password: "", //?
        menu_order: "", //?
        post_date: "", //?
        post_author: "", //?
        comment_status: "open", //?
        sku: "", //?
        parent_sku: "", //?
        children: "", //?
        downloadable: "no", //?
        virtual: "no", //?
        stock: "", //?
        regular_price: "", //?
        sale_price: "", //?
        weight: "", //?
        length: "", //?
        width: "", //?
        height: "", //?
        tax_class: "", //?
        visibility: "",
        stock_status: "instock", //?
        backorders: "no", //?
        sold_individually: "no", //?
        low_stock_amount: "", //?
        manage_stock: "no", //?
        tax_status: "taxable", //?
        upsell_ids: "", //?
        crosssell_ids: "", //?
        purchase_note: "", //?
        sale_price_dates_from: "", //?
        sale_price_dates_to: "", //?
        download_limit: "'-1", //?
        download_expiry: "'-1", //?
        product_url: "", //?
        button_text: "", //?
        images: buildImage(i.gallery), // http://ce99336-wordpress-eo1hr.tw1.ru/msk/wp-content/uploads/sites/2/2023/10/850031c4a9601bc16e04701b46130576-scaled.jpg ! alt :  ! title : 850031c4a9601bc16e04701b46130576 ! desc :  ! caption :  |
        downloadable_files: "", //?
        product_page_url: "", //?
        "meta:total_sales": "0",
        "meta:_yoast_wpseo_focuskw": "",
        "meta:_yoast_wpseo_canonical": "",
        "meta:_yoast_wpseo_bctitle": "",
        "meta:_yoast_wpseo_meta-robots-adv": "",
        "meta:_yoast_wpseo_is_cornerstone": "",
        "meta:_yoast_wpseo_metadesc": "",
        "meta:_yoast_wpseo_linkdex": "",
        "meta:_yoast_wpseo_estimated-reading-time-minutes": "1",
        "meta:_yoast_wpseo_content_score": "",
        "meta:_yoast_wpseo_title": "",
        "meta:_yoast_wpseo_metakeywords": "",
        "tax:product_type": "simple",
        "tax:product_visibility": "",
        "tax:product_cat":  buildCategory(
          metro_category, zali_category, 
          kanoni_category, kogda_category, stoimost_category, 
          okrug_category, crematory_category, morg_category, 
          rajon_category, catgory_type
        ),  
          //"Поминки|Поминки > Поминки ВДНХ > Поминки ВДНХ 10 человек|Поминки > Поминки зал > Поминки зал 10 человек|Поминки > Поминки кафе|Поминки > Поминки кафе > поминки кафе 10 человек|Поминки > Поминки кафе > поминки кафе 20 человек|Поминки > Поминки ресторан|Поминки > Поминки ресторан > Поминки ресторан 10 человек|Поминки > Поминки ресторан > Поминки ресторан 20 человек",
        "tax:product_tag": i.restaurant.cardTitle.replace(/,/g, ""),
        "tax:product_shipping_class": "",
        "attribute:pa_mesto": "Какоето место",
        "attribute_data:pa_mesto": "0|1|0",
        "attribute:pa_metro": pa_metro,// i.restaurant.pa_mero.length > 0 ? i.restaurant.pa_mero.join("|"): '',// "ВДНХ|Войковская|Медведково|Ховрино|Ховрино2|Ховрино3",
        "attribute_data:pa_metro": "1|1|0",
        "attribute:pa_okrug": pa_okrug,
        "attribute_data:pa_okrug": "2|1|0", 
        "attribute:pa_po-kanonam":
          "Иудейские|Католические|Мусульманские/в исламе",
        "attribute_data:pa_po-kanonam": "3|1|0",
        "attribute:pa_rajon": pa_rajon,
        "attribute_data:pa_rajon": "4|1|0",
        "attribute:pa_uslugi": "Дома|кладбище|на работе|С доставкой",
        "attribute_data:pa_uslugi": "5|1|0",
        "attribute:pa_vmestimost" :zali_category,
        "attribute_data:pa_vmestimost": "6|1|0",
        "attribute:pa_vremya-provedeniya":
          "40 дней|9 дней|в день|годовщина|день похорон|завтра|сегодня",
        "attribute_data:pa_vremya-provedeniya": "7|1|0",
        "attribute:pa_zavedeniya": "Банкетный зал|Зал|Кафе|Ресторан|Столовая", //catgory_type
        "attribute_data:pa_zavedeniya": "8|1|0",
        "attribute:pa_coordinati": `${i.restaurant.point.coordinates[0]}, ${i.restaurant.point.coordinates[1]}`,
        "attribute_data:pa_coordinati": "9|1|0",
      };

      return ImportWoocommerse;
    });
return modDataToExport;  
};

export default handleLoadinfForImport;