import { useApiVK, useSearchOnBD } from "shared";
import map from "shared/MapVKSingleton/MapVKSingleton";

const handleSuggest = () => {
  const {searchVKbyRef } = useApiVK();
  const onHandle = (ref, type, setCoord, clear) => {
 
    

    function centrMap(arrLatLon) {
      map.centerMapOnCoordinates(arrLatLon);
      map.addMarkerToMap(arrLatLon, type);
    }

    function index(data) {
      let arrLatLon = data.pin;
      centrMap([arrLatLon[0], arrLatLon[1]]);
      setCoord({type: type , data: [arrLatLon[0], arrLatLon[1]] });
      clear({type: type});
    }

    
    searchVKbyRef(ref, (data)=> index( data))
  };

  return { onHandle };
};

export default handleSuggest;
