import styles from './Halls.module.scss'
import React from 'react'

import { Separator } from 'components'
import { Hall } from './components'

import { ManagerRestaurantContext } from 'context'

export default function Halls() {
  const {
    data: { halls },
    halls: added,
    handleAddHall,
  } = React.useContext(ManagerRestaurantContext)

  console.log(added);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Поминальные залы</div>
      <Separator />
      {halls.map((hall, i) => (
        <Hall
          key={i}
          hall={hall}
          added={added.indexOf(hall.id) !== -1}
          //onAddClick={() => handleAddHall(i)}
          onAddClick={() => handleAddHall(hall.id)}
        />
      ))}
    </div>
  )
}
