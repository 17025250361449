import { ImputOptionPlaces } from "shared/ImputOptionPlaces";
import { ImputOptionRestaurants } from "shared/ImputOptionRestauranta";
import { ImputOptionSuggest } from "shared/ImputOptionSuggest"

export default function OptionGroup() {
    return(<></>)
}

OptionGroup.Suggest = ImputOptionSuggest;
OptionGroup.Restaurants = ImputOptionRestaurants;
OptionGroup.Places = ImputOptionPlaces;