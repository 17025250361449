import { MODELS } from "constants";
import {  api } from "utils";
import handleLoadinfForImport from "./handleLoadinfForImport";

// получаю атрибуты через подгрузку по апи
async function startExporsHalls (data, callbeck ) {
    let dataObject = await api.getAll({model: MODELS.OBJECT.VALUE, params: {limit: 10000} });
      const modDataToExport = await Promise.all(data.map(async (i) => {
          if (i.restaurant.objects.length > 0) {
            let pa_metro = [];
            let pa_rajon = [];
            let pa_okrug = [];         
  
            await Promise.all(i.restaurant.objects.map(async (i) => {           
              
              if (i.title !== "") {      
                
                const foundItem = dataObject.data.rows.find(item => item.id === i.title );              
                if (foundItem.type == "METRO") { 
                  pa_metro.push(foundItem.title.split(',')[0]);
                }
                if (foundItem.type == "ZONE") { 
                  pa_okrug.push(foundItem.title.split(',')[0]);
                }             
                if (foundItem.type == "DISTRICT") { 
                  pa_rajon.push(foundItem.title.split(',')[0]);
                }          
              }
            }));
            i.restaurant.pa_metro = pa_metro; 
            i.restaurant.pa_rajon = pa_rajon; 
            i.restaurant.pa_okrug = pa_okrug; 
          }
           
          return i; 
        }));     
     const test = await handleLoadinfForImport(modDataToExport);
     await callbeck(test);
        
    }

    export default startExporsHalls;