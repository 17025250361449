const data = {
  "public_transport/station_subway": "Станция метро",
  "amenity/marketplace": "Маркетплейс",
  "shop/general/mall": "Торговый центр",
  "leisure/cinema": "Кинотеатр",
  "public_transport/stop_bus": "Автобусная остановка",
  "landuse/cemetery": "Кладбище",
  "public_transport/station_train": "Железнодорожная станция",
  "aeroway/aerodrome": "Аэродром",
  "amenity/shelter": "удобства/убежище",
  "tourism/attraction": "Туристическое место",
  "amenity/crematorium": "Крематорий",
  "food/restaurant":"Ресторан",
  "shop/body/beauty":"Салон красоты",
  "leisure/arts_centre":"Центр досуга",
  "education/college":"Колледж",
  "education/school":"Школа",
  "shop/food/alcohol": "Алкоголь",
  "entrance": "Вход в здание",
  "landuse/commercial":"Коммерческое здание",
  "public_transport/stop_subway":"Остановка метро",
  "amenity/prison": "Тюрьма",
  "food/cafe":"Кафе",
};

const  vkTypeTranslate = (typeData) => {
   
  const type = data[typeData] ? data[typeData] : typeData;
  console.log(typeData, type);
  return type;
}

export default vkTypeTranslate; 
