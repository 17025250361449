import styles from "./AdminForm.module.scss";
import React from "react";

import { Button } from "components";
import {
  Address,
  Contacts,
  Control,
  Group,
  Inputlist,
  Shedule,
} from "./components";

import { classNames } from "utils";
import {
  handleCancel,
  handleDelete,
  handleSave,
  handleSaveHalls,
} from "./handlers";
import { EditRestaurantContext } from "context";

export default function AdminForm({
  id,
  className,
  children,
  model,
  title,
  onCancel,
  onSave,
  onError,
  deleteButton,
}) {

  return (
    <form
      className={classNames(styles.container, [className])}
      onSubmit={function (e) {
        e.preventDefault();

        const elements = Array.from(e.target.elements);
        const data = {};

        elements.forEach(({ name, value }) => {
          if (!name) {
            return;
          }

          const props = name.split(".");

          if (props.length === 1) {
            return (data[name] = value);
          }

          let container = data;

          props.map((key, index, values) => {
            let prevProp = values[index - 1];
            let out = value;

            try {
              out = JSON.parse(value);
            } catch (error) {}

            const isArray = key.includes("[");
            const isPrevArray = index > 0 && prevProp.includes("[");
            const isLastProp = index === values.length - 1;

            const arrayRegex = /\[[^\]]*\]/g;

            if (isArray) {
              const arrayKey = key.replace(arrayRegex, "");
              const array = container[arrayKey] ? container[arrayKey] : [];

              if (isPrevArray) {
                const index = prevProp.split("[").pop().split("]")[0];
                const array = container[index][arrayKey]
                  ? container[index][arrayKey]
                  : [];

                return (container = container[index] =
                  {
                    ...container[index],
                    [arrayKey]: [...array, out],
                  });
              }

              return (container = container[arrayKey] =
                isLastProp ? [...array, out] : [...array]);
            }

            if (isPrevArray && key) {
              const index = prevProp.split("[").pop().split("]")[0];

              return (container = container[index] =
                {
                  ...container[index],
                  [key]: out,
                });
            }

            if (isLastProp) {
              container = container[key] = out;
            } else {
              container = container[key] = { ...container[key] };
            }
          });
        });

        /** Костыль handleSaveHalls
         * Так как пришлось выносить залы в базе данных, (изначально залы это просто текст в 1 м поле ресторана)
         * Поэтому сохранение залов будет происходить в отдельной функции
         * Для устранения требуется сделать для зала свою форму
         */
        async function handle() {
         await handleSaveHalls({ data, model, onSave, onError, id });
         await handleSave({ data, model, onSave, onError, id });         
        }
        handle();
      }}
    >
      {(title || id) && (
        <React.Fragment>
          <div className={styles.title}>{title}</div>
          {/* {id && deleteButton && (
            <div
              className={styles.removeButton}
              onClick={handleDelete({
                onDelete: deleteButton.onDelete,
                message: deleteButton.message,
                model,
                id,
              })}
            >
              {deleteButton.text}
            </div>
          )} */}
        </React.Fragment>
      )}
      {children}
      <div className={`${styles.actions} row`}>
        <Button text="Сохранить" className="col col-2" />
        {onCancel && (
          <Button
            mode="light"
            text="Отменить"
            className="col col-2"
            onClick={handleCancel(onCancel)}
          />
        )}
      </div>
    </form>
  );
}

AdminForm.Address = Address;
AdminForm.Contacts = Contacts;
AdminForm.Control = Control;
AdminForm.Group = Group;
AdminForm.Inputlist = Inputlist;
AdminForm.Shedule = Shedule;
