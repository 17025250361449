import { InfoAboutRout } from "entities/InfoAboutRout";
import { BuildRout } from "features/BuildRout";
import { UseSerchRestaurants } from "features/UseSerchRestaurants";
import styles from "./ServisSearcForVK.module.css"

export default function ServisSearcForVK() {
  console.log("render ServisSearcForVK");

  return (
    <>
      <UseSerchRestaurants type="first"></UseSerchRestaurants>
      <UseSerchRestaurants type="second"></UseSerchRestaurants>
      <div className={styles.container}>
        <BuildRout /> <InfoAboutRout />
      </div>
    </>
  );
}
