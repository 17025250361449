//import styles from "./Filter.module.scss";

import React, { useState, useEffect } from "react";
import { SelectFilterObjects } from ".";
import { Typography, Tag } from "antd";
import { ManagerOfferContext } from "context";
import handlerAddFilter from "../utils/handlerAddFilter";

export default function FilterForObjects() {
  // значение в инпутах поиска
  const [reset, setReset] = useState(true);
  const {
    filterRestaurants,
    setFilterRestaurants,
    setDataForFilterReataurants,
  } = React.useContext(ManagerOfferContext);

  // сброс значений
  const handleReset = (value) => {
    setReset(!reset);
    handlerAddFilter([], setDataForFilterReataurants, "objects");
  };

  
// запись в список объектов, промежуточное действие потому что это массив объектов напрямую записать нельзя
  useEffect(() => {

    // console.log(filterRestaurants)

    if (filterRestaurants.length === 0) {
      handlerAddFilter({}, setDataForFilterReataurants, "objects");
    }
    const modiData = filterRestaurants?.map((i) => {
      return { title: i.id };
    });

    handlerAddFilter(modiData, setDataForFilterReataurants, "objects");
  }, [filterRestaurants]);

  const { Title } = Typography;

// удаление объекта из списка
  function preventDefault(id) {
    setFilterRestaurants((prevData) => {
      const filterData = prevData.filter((i) => i.id != id);
      console.log(filterData);
      return filterData;
    });
  }

  return (
    <React.Fragment>
      {filterRestaurants?.length > 0 && (
        <Tag color="volcano" onClick={() => handleReset(["reset"])}>
          Очистить объекты
        </Tag>
      )}
      {filterRestaurants?.map((k) => (
        <Tag key={k.id} closeIcon onClose={() => preventDefault(k.id)}>
          {k.title}
        </Tag>
      ))}
      <SelectFilterObjects type="CEMETERY" reset={reset} />
      <SelectFilterObjects type="CREMATORY" reset={reset} />
      <SelectFilterObjects type="MORGUE" reset={reset} />
      <SelectFilterObjects type="METRO" reset={reset} />
      <SelectFilterObjects type="DISTRICT" reset={reset} />
      <SelectFilterObjects type="ZONE" reset={reset} />
    </React.Fragment>
  );
}
