import style from "./MapVkLegend.module.css";

const MapVkLegend = () => {
  return (
    <div>
      <strong>Условные обозначения:</strong>
      <br></br>
      <br></br>
      <div className={style.legend_list}>
        <div
          className={`${style.legend_circle} ${style.legend_circle_blue}`}
        ></div>
        - возим продукты
      </div>
      <div className={style.legend_list}>
        <div
          className={`${style.legend_circle} ${style.legend_circle_foodPice}`}
        ></div>
        - готовят пирожки
      </div>
      <div className={style.legend_list}>
        
        ---- <a href="https://chat.whatsapp.com/Ifho99hMcF2Fqsx1w5MA3X" target="_blank"> Задать вопрос (группа Карта СПЗ)</a>
      </div>
      
    </div>
  );
};

export default MapVkLegend;
