import styles from './Table.module.scss'
import React from 'react'

import { Group, Whatsapp } from './'

import { ManagerRestaurantContext } from 'context'
import { getShedule } from './utils'

export default function Table() {
  const {
    data: {
      id,
      idFromCompany,
      numberOfFloors,
      status,
      priority,
      food,
      delivery,
      prepay,
      halls,
      mondayStart,
      mondayEnd,
      tuesdayStart,
      tuesdayEnd,
      wednesdayStart,
      wednesdayEnd,
      thursdayStart,
      thursdayEnd,
      fridayStart,
      fridayEnd,
      saturdayStart,
      saturdayEnd,
      sundayStart,
      sundayEnd,
      managerInfo: { shedule, contacts, whatsapp, comment },
      clientInfo: { shedule: clientShedule },
    },
  } = React.useContext(ManagerRestaurantContext)  

  return (
    <div className={styles.container}>
      <Group
        rows={[
          // ['ID заведения', id],
          ['ID (операционная таблица)', idFromCompany],
          ['Количество этажей', numberOfFloors],
          ['Статус ресторана', status],
          ['Приоритет загрузки', priority],
          ['Продукты', food],
          ['Доставка', delivery],
          ['Предоплаты', prepay],
        ]}
      />

      <Group
        title="Залы"
        rows={halls.map(({ title, boarding, fit }) => [
          title,
          `от ${boarding} до ${fit} человек`,
        ])}
      />
      

      {/* <Group
        title="Режим работы"
        rows={[
          //['Реальный', getShedule(shedule)],
          ['Для клиента', getShedule(clientShedule)],
        ]}
      /> */}
      <br></br>
      <h3>Режим работы</h3>      
      <div>Пн с {mondayStart} - {mondayEnd}</div>
      <div>Вт с {tuesdayStart} - {tuesdayEnd}</div>
      <div>Ср с {wednesdayStart} - {wednesdayEnd}</div>
      <div>Чт с {thursdayStart} - {thursdayEnd}</div>
      <div>Пт с {fridayStart} - {fridayEnd}</div>
      <div>Сб с {saturdayStart} - {saturdayEnd}</div>
      <div>Вс с {sundayStart} - {sundayEnd}</div>

      <Group
        title="Контакты"
        rows={contacts?.map(({ phone, fullname, position }) => [
          [fullname, position].join(', '),
          phone,
        ])}
      />

      {whatsapp.length > 0 && (
        <Group title="Группа в WhatsApp">
          <Whatsapp url={whatsapp} />
        </Group>
      )}
    </div>
  )
}
