import React from 'react';
import {  Modal } from 'antd';
import PropTypes from 'prop-types';



const EditModal = ({children, isOpen, setIsOpen}) => {
  return (
    <>      
      <Modal
        title="Редактирование ресторана"
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        width="44vw"        
      > {children}
        <p>*** Памятка по заполнению</p>        
      </Modal>
    </>
  );
};

EditModal.propTypes = {
  children: PropTypes.element,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
}
export default EditModal;