import React, { useEffect, useState } from "react";
import { Select, Button, Col, Row } from "antd";
import { ManagerOfferContext } from "context";
import useApiVK from "./useApiVK";
import useApiMapManager from "./useApiMapManager";
import { useSelector } from 'react-redux';
import useSearch from "./useSearch";
//import transferringFirstValueToRouter from "./transferringFirstValueToRouter";
const VKMAP = process.env.REACT_APP_VKMAPS_API_KEY;

export default function SearchInput(props) {
  const { suggestVK, searchPlacesInterest } = useApiVK();

  const {} = useApiMapManager();

  // определяем тип маркера
  // const { setSelectPosition, searched, setSearch, setModalFor } =
  //   React.useContext(ManagerOfferContext);
  const { setSelectPosition, setModalFor, setMapSettings } =
    React.useContext(ManagerOfferContext);


    //const searchRestaurants = useSelector((state) => state.searchRestaurants.value) 
//console.log(searchRestaurants)

const [search, setSearch] = React.useState('')

const [searched] =
useSearch(search, setMapSettings, setModalFor)

 const {serchForRestaurants, buildRestaurants, setBuildRestaurants, checkActivInput, isUseInputActiv, setUseInputActiv} = useApiMapManager(searched, setSearch, props.type);

  const [data, setData] = useState([]);
  // поиск мест интереса
  const [dataPlacesInterest, setDataPlacesInterest] = useState([]);
  const [value, setValue] = useState(props.type == 'first' ? props.valueFirstInput : '' );
  // контролирую размер введенных данных что бы при стирании не було повторых запросов
  const [controlLengthSearchValue, setControlLengthSearchValue] = useState("");


  const handleSearch = (newValue, type) => {
    checkActivInput(type);    
    props.setSelectPosition(null);   

    setValue(newValue);
    // проверяем на конце цифра или нет, для того что бы suggest сработал сразу
    function isLastCharacterDigit(str) {
      /// ОГРАНИЧЕНИЕ ЛИШНЕГО ПОИСКА
      // Проверяем, является ли последний символ строки цифрой
      return /\d$/.test(str);
    }
    setControlLengthSearchValue(newValue);



    // запрет поиска при стирании
    serchForRestaurants(newValue);
    if (newValue.length < controlLengthSearchValue.length) {
      setData([]);
    setDataPlacesInterest([]); 
      return;
    }

    if (
      (newValue.length > 5 && newValue.length != 0) ||
      isLastCharacterDigit(newValue)
    ) {
      suggestVK(newValue, setData);
      searchPlacesInterest(newValue, setDataPlacesInterest);
      // поиск по ресторанам
    }
  };
// подставляю данные во first
useEffect(()=> {
  if(props.type == 'one') {
    props.setValueFirstInput(value)
 }
}, [value])


  const handleChange = (newValue, newLabel) => {
    // переделать костыль (данные по ресторанам не стираются в соседних окнах)
    setUseInputActiv(false)
    console.log(newValue, "пробую искать");
    // запрос координат
    console.log("------------GEOkoding VK --------------");
    fetch(
      `https://maps.vk.com/api/search?q=${newValue}&api_key=${VKMAP}&fields=address,pin&limit=1`
    )
      .then((response) => response.json())
      .then((d) => {
        const data = { lon: d.results[0].pin[0], lat: d.results[0].pin[1] };
        // устанавливаем маркет на карту
        setSelectPosition(data);
        // устанавливаем данные для маршрута если тип нужный
        if (props.type == "first" || props.type == "second") {
          props.setRoutData((prevData) => {
            return { ...prevData, [props.type]: data };
          });
        } else {
          props.setRoutData((prevData) => {
            return { ...prevData, first: data };
          }); }
         


      });

    setValue(newLabel);
    // обнуляем значения после выбора
    //resetInput()
    setData([]);
    setDataPlacesInterest([]);
  };

  // обработчик Enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Вызываем функцию handleChange с текущим значением
      handleChange(data[0].value || event.target.value);
    }
  };

  function resetInput() {    
    setDataPlacesInterest([]);    
    props.setSelectPosition(null);
    //props.setDataPointForRoutVKMap([])
  }

/// показывать или нет контейнер с данными
  const isVisibleSelectOpion = dataPlacesInterest.length > 0 || data.length > 0 || buildRestaurants.length > 0 ? true : false;
/// стереть данные из формы


function eraseData() {
  // переделать костыль
  setUseInputActiv(false);
  setBuildRestaurants([]) 
  setValue("");
  setData([]);
  setDataPlacesInterest([]);    
  props.setSelectPosition(null);
}

function handleOnRestaurants(value, label, id, point) {
  setUseInputActiv(false);
  setValue(value);
 // console.log(value, label, id, point )
  const data = { lon: point[1], lat: point[0] };
  props.setSelectPosition(data);
  if (props.type == "first" || props.type == "second") {
    props.setRoutData((prevData) => {
      return { ...prevData, [props.type]: data };
    });
  } else {
    props.setRoutData((prevData) => {
      return { ...prevData, first: data };
    });
  }
  //setModalFor(id)
}

  return (
    <>
      

      <div style={{position: 'relative', width: "100%", border: 'solid 1px #d9d9d9', borderRadius: 8}}>
        <div style={{display: "flex"}}>< input placeholder={props.placeholder} value={value} style={{width: "100%", height: 30, borderRadius: 6}} onInput={(e)=> handleSearch(e.target.value, props.type)}/> <button onClick={()=> eraseData('')} style={{position: "absolute", right: "10px", top: "4px"}}  >x</button></div>
         {isUseInputActiv && isVisibleSelectOpion && <div style={{position: "absolute", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", padding: 10, zIndex: 2,  backgroundColor: "#fff", width: "100%", border: 'solid 1px #d9d9d9',}}>
          {data.length > 0 && <div style={{color: "blueviolet"}}  >подсказка адреса</div>}
          {data?.map((i) => <button  onClick={()=>handleChange(i.value, i.label)} key={Math.random()} style={{padding: "5px", width: "100%", textAlign: "left"}}>{i.label}</button>)} 
          {dataPlacesInterest.length > 0 && <div style={{color: "blueviolet"}}>поиск мест интереса</div>}
          {dataPlacesInterest?.map((i) => <button onClick={()=>handleChange(i.value, i.label)} key={Math.random()} style={{padding: "5px", width: "100%", textAlign: "left"}}>{i.label}</button>)}           
          
          {buildRestaurants.length > 0 && <div style={{color: "blueviolet"}}>поиск по ресторанам</div>}
          {buildRestaurants?.map((i) => <button onClick={()=>handleOnRestaurants(i.value, i.label, i.id, i.point)} key={Math.random()} style={{padding: "5px", width: "100%", textAlign: "left"}}>{i.label}</button>)}           
                  
          </div>}          
      </div>
    </>
  );
}
