import styles from './WorkspacePage.module.scss'
import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import { Filter, Map, MapOsmLeaflet, Offer, RestaurantModal, Tools } from './components'

import { ManagerOfferContext } from 'context'
import { useHandlers, useSearch } from './hooks'
import { OBJECT_TYPES } from 'constants'
import { USER_ROLES } from 'constants'
import { getObjKeyName } from 'utils'

export default function WorkspacePage() {
  const { setRoles, user } = useOutletContext()
  setRoles([
    getObjKeyName(() => USER_ROLES.ADMIN),
    getObjKeyName(() => USER_ROLES.MANAGER),
    getObjKeyName(() => USER_ROLES.REDAKTOR),
  ])

  const [modalFor, setModalFor] = React.useState()

  const [offer, handle] = useHandlers(user)

  const [filterVisible, setFilterVisible] = React.useState(false)
  const [visibleObjects, setVisibleObjects] = React.useState([]); // Object.keys(OBJECT_TYPES) инициализация активных кнопок
  //данные для фильтрации ресторана
  const [filterRestaurants, setFilterRestaurants] = React.useState([]);
  // набор всех значений всех фильтров
  const [dataForFilterHalls, setDataForFilterHalls] = React.useState({});
  const [dataForFilterReataurants, setDataForFilterReataurants] = React.useState({});

  // применение фильтра по кнопке
  const [handleSearch, setHandleSearch] = useState(false);

  const [radiusFilter, setRadiusFilter] = React.useState({})

  const [search, setSearch] = React.useState('')

  const [mapSettings, setMapSettings] = React.useState({})


// установка координаты точки при поиске
     const [selectPosition, setSelectPosition] = useState(null);
     // координаты для построения маршрута VK карты
     const [dataPointForRoutVKMap, setDataPointForRoutVKMap ] = useState([])

     // данные для отображения расстояния и времени маршрута
     const [dataForRoutDistanceTime, setDataForRoutDistanceTime] = useState({distanse: '', time: ''})
 
   console.log("render WorkspacePage")  

  return (
    <ManagerOfferContext.Provider
      value={{
        handle,
        offer,
        setModalFor,
        visibleObjects,
        setVisibleObjects,
        radiusFilter,
        setRadiusFilter,
        filterVisible,
        setFilterVisible,
        setSearch,
        //searched,
        //searchedCoords,
        mapSettings,
        setMapSettings,
        //searchedVisible,
        //setSearchedVisible,
        filterRestaurants, 
        setFilterRestaurants,
        dataForFilterHalls, 
        setDataForFilterHalls,
        dataForFilterReataurants, 
        setDataForFilterReataurants,
        handleSearch, 
        setHandleSearch,
        // для закрытия окна фильтра
        modalFor,
        selectPosition, 
        setSelectPosition,
        setDataPointForRoutVKMap,
        dataPointForRoutVKMap,
        dataForRoutDistanceTime, 
        setDataForRoutDistanceTime
      }}
    >
      <Tools />      
      {/* <Map />   */}
      <MapOsmLeaflet/>    
      <Offer />
      {modalFor && (
        <RestaurantModal id={modalFor} onClose={() => setModalFor(null)} />
      )}
    </ManagerOfferContext.Provider>
  )
}
