import styles from "./Tools.module.scss";
import {
  FilterForCapacityPeople,
  FilterForDate,
  FilterForObjects,
  FilterForPriopity,
  FilterForStatus,
  FilterModal,
  FilterNumberOfFloors,
  ServisMapVK,
} from "./components";
import { Button, Search } from "./";

import { ManagerOfferContext } from "context";
import iconFilter from "assets/sprites/filter.svg";
import React, { useEffect, useState } from "react";
import { Radius, VisibleObjects } from "./components/FilterModal";
import { handleFilterObjects } from "./components/FilterModal/handlers";
//import { Button, Drawer, theme } from 'antd';
import { FilterOutlined } from "@ant-design/icons";

import { Drawer, Space, FloatButton } from "antd";

export default function Tools() {
  const { filterVisible, setFilterVisible, setHandleSearch } =
    React.useContext(ManagerOfferContext);

  const {
    visibleObjects,
    setVisibleObjects,
    modalFor,
    selectPosition,
    setSelectPosition,
  } = React.useContext(ManagerOfferContext);

  //
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const showDefaultDrawer = () => {
    setSize("default");
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  //закрыте формы фильтра при открытия формы ресторана
  useEffect(() => {
    setOpen(false);
  }, [modalFor]);

  const onSearch = () => {
    setHandleSearch((pevData) => !pevData);
  };

  // кнопка
  const Float = () => (
    <FloatButton
      style={{
        right: 24 + 60,
      }}
      icon={<FilterOutlined />}
      shape="square"
      onClick={showDefaultDrawer}
      tooltip={<div>Фильтр</div>}
    />
  );

  return (
    <React.Fragment>      
      <Drawer
        title={"Закрыть"}
        placement="left"
        //size={size}
        onClose={onClose}
        open={open}
        maskClosable={false}
        maskStyle={{ background: "none", pointerEvents: "none" }}
        style={{ width: "450px" }}
        extra={
          <Space>
            {/* <button onClick={onSearch}>Применить</button> */}
            {/* <button  onClick={onClose}>
              OK
            </button> */}
          </Space>
        }
      >
        <>          
          <ServisMapVK></ServisMapVK>
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
           <FilterForStatus></FilterForStatus> 
          </div>
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
           <FilterForPriopity></FilterForPriopity> 
          </div>

          
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
            <FilterNumberOfFloors />
            {/* <Search /> */}
          </div>
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
            <FilterForCapacityPeople />
          </div>
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
            <FilterForDate />
          </div>
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
            <FilterForObjects />
          </div>
          <div
            style={{
              border: "solid 1px #d9d9d9",
              padding: "5px",
              borderRadius: "5px",
              marginTop: "5px",
              backgroundColor: "#f2f3f9",
            }}
          >
            <VisibleObjects
              visibleObjects={visibleObjects}
              onFilter={(checkActive, key) =>
                handleFilterObjects(setVisibleObjects, checkActive, key)
              }
            />            
          </div>
          
          {/* <Radius /> */}
        </>
      </Drawer>
      <Float />
    </React.Fragment>
  );
}
