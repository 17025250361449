import styles from "./ImputOptionPlaces.module.css";
import { Button, Typography } from "antd";

export default function ImputOptionPlaces({
  data,
  handle,
  setValueInput,
  type,
  setCoord,
  clear,
  input,
}) {
  function onHandle() {
    const { onHandle } = handle();
    onHandle(data, type, setCoord, clear);
    setValueInput({ data: data.label, type: type });
  }

  // Функция для выделения совпадений
  const highlightMatches = (label, input) => {
    if (!input) return label;

  // Удаляем квадратные и фигурные скобки из input
  const cleanedInput = input.replace(/[[{}]/g, '');

  // Разбиваем очищенный input на слова и фильтруем пустые значения
  const words = cleanedInput.split(" ").filter(Boolean);
  let result = label;

    words.forEach((word) => {
      // Создаем регулярное выражение для поиска слова (без учета регистра)
      const regex = new RegExp((`${word}`), 'gi');
      // Заменяем найденные слова на выделенные
      result = result.replace(regex, `<strong>${word}</strong>`);
    });

    return result;
  };

  return (
    <div type="dashed" className={styles.wrapper} onClick={() => onHandle()}>
      {/* Используем dangerouslySetInnerHTML для вставки HTML */}
      <div dangerouslySetInnerHTML={{ __html: highlightMatches(data.label, input) }} />
      <Typography.Text italic>{data.type}</Typography.Text>
    </div>
  );
}
