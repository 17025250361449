/**По документации импорт делается так, но есть ошибки, поэтому
 * импорт сделал через head там 2 ссылки на библиотеки
 *
 * import mmrgl from "mmr-gl";
 * import "mmr-gl/dist/mmr-gl.css";
 */

import store from "../../store/store";
import { openPopup, setCoordinates, setInput } from "../../store/index";
const REACT_APP_VKMAPS_API_KEY = process.env.REACT_APP_VKMAPS_API_KEY;

import markerRestaurant1 from "../../assets/icon/marker-restaurant-1.svg";
import markerRestaurant2 from "../../assets/icon/marker-restaurant-2.svg";
import markerRestaurant3 from "../../assets/icon/marker-restaurant-3.svg";
import markerRestaurant4 from "../../assets/icon/marker-restaurant-4.svg";
import markerRestaurant5 from "../../assets/icon/marker-restaurant-5.svg";

class MapVKSingleton {
  constructor() {
    if (!MapVKSingleton.instance) {
      this.map = null;
      this.currentPopup = null;
      MapVKSingleton.instance = this;
      console.log("render VKmap");
      this.markers = { first: null, second: null };
    }
    return MapVKSingleton.instance;
  }

  _getIcon(priority) {
    const icon = {
      1: markerRestaurant1,
      2: markerRestaurant2,
      3: markerRestaurant3,
      4: markerRestaurant4,
      5: markerRestaurant5,
    };

    if (!icon[priority]) {
      return markerRestaurant5;
    }

    return icon[priority];
  }


  // функционал самого попапа для построения маршрута
  _getPopupHandle(coordinates) {
    // Создаём контейнер для попапа
    const popupContent = document.createElement("div");
    popupContent.style.padding = "20px"; 
    popupContent.style.borderRadius = "8px"; 
    popupContent.style.backgroundColor = "#fff"; 
    popupContent.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)"; 
    popupContent.style.width = "200px"; 
    popupContent.style.display = "flex"; 
    popupContent.style.flexDirection = "column"; 
    popupContent.style.gap = "10px"; 

    const button1 = document.createElement("button");
    button1.textContent = "Маршрут от сюда";
    button1.style.padding = "10px"; 
    button1.style.border = "none"; 
    button1.style.borderRadius = "5px"; 
    button1.style.backgroundColor = "#4CAF50"; 
    button1.style.color = "#fff"; 
    button1.style.cursor = "pointer"; 
    button1.style.transition = "background-color 0.3s"; 

    button1.addEventListener("mouseover", () => {
        button1.style.backgroundColor = "#45a049"; 
    });

    button1.addEventListener("mouseout", () => {
        button1.style.backgroundColor = "#4CAF50"; 
    });

    button1.addEventListener("click", () => {
        this.currentPopup.remove();
        store.dispatch(
            setCoordinates({
                data: [coordinates.lng, coordinates.lat],
                type: "first",
            })
        );
        store.dispatch(
            setInput({
                data: "Маршрут от сюда",
                type: "first",
            })
        );
        this.addMarkerToMap([coordinates.lng, coordinates.lat], "first");
    });
    
    popupContent.appendChild(button1);

    // Кнопка 2
    const button2 = document.createElement("button");    
    button2.textContent = "Маршрут сюда";
    button2.style.padding = "10px"; 
    button2.style.border = "none"; 
    button2.style.borderRadius = "5px"; 
    button2.style.backgroundColor = "#008CBA"; 
    button2.style.color = "#fff"; 
    button2.style.cursor = "pointer"; 
    button2.style.transition = "background-color 0.3s"; 

    button2.addEventListener("mouseover", () => {
        button2.style.backgroundColor = "#007B9E"; 
    });

    button2.addEventListener("mouseout", () => {
        button2.style.backgroundColor = "#008CBA"; 
    });

    button2.addEventListener("click", () => {
        this.currentPopup.remove();
        store.dispatch(
            setCoordinates({
                data: [coordinates.lng, coordinates.lat],
                type: "second",
            })
        );
        store.dispatch(
            setInput({
                data: "Маршрут сюда",
                type: "second",
            })
        );
        this.addMarkerToMap([coordinates.lng, coordinates.lat], "second");
    });

    popupContent.appendChild(button2);

    return popupContent;
}


  _getHtmlMarker(
    priority = 1,
    title = "",
    id = "",
    food = "Не определено",
    foodPies = 0
  ) {
    console.log(foodPies);
    const imgUrl = this._getIcon(priority);

    const titleMarker = document.createElement("div");
    titleMarker.style.position = "absolute";
    titleMarker.style.top = "-20px";
    titleMarker.style.textWrap = "nowrap";
    titleMarker.style.color = "black";
    titleMarker.innerHTML = title;
    titleMarker.style.backgroundColor = "#ffffff";
    titleMarker.style.borderColor = "rgb(217, 217, 217)";
    titleMarker.style.borderStyle = "solid";
    titleMarker.style.borderWidth = "1px";
    titleMarker.style.borderRadius = "20px";
    titleMarker.style.padding = "2px";
    titleMarker.style.display = "none";

    const div = document.createElement("div");

    div.style.width = "32px";
    div.style.height = "42px";

    div.style.backgroundImage = `url(${imgUrl})`;
    div.style.backgroundSize = "cover";

    div.addEventListener("mouseover", function () {
      titleMarker.style.display = "block";
    });

    div.addEventListener("mouseout", function () {
      titleMarker.style.display = "none";
    });

    div.addEventListener("click", function () {
      store.dispatch(openPopup(id));
    });

    if (food.includes("рублей")) {
      const isFood = document.createElement("div");
      isFood.style.position = "absolute";
      isFood.style.height = "12px";
      isFood.style.width = "12px";
      isFood.style.top = "-4px";
      isFood.style.borderRadius = "50px";
      isFood.style.backgroundColor = "#0972e1";
      div.appendChild(isFood);
    }

    if (foodPies > 0) {
      const elementsFoodPies = document.createElement("div");
      elementsFoodPies.style.position = "absolute";
      elementsFoodPies.style.height = "12px";
      elementsFoodPies.style.width = "12px";
      elementsFoodPies.style.top = "-4px";
      elementsFoodPies.style.right = "0px";
      elementsFoodPies.style.borderRadius = "50px";
      elementsFoodPies.style.backgroundColor = "#4B0082";
      div.appendChild(elementsFoodPies);
    }

    div.appendChild(titleMarker);
    return div;
  }

  _getColorMarcer(type) {
    let color = "";
    switch (type) {
      case "first":
        color = "red";
        break;
      case "second":
        color = "green";
        break;
      default:
        color = "#3FB1CE";
        break;
    }

    return color;
  }

  // центрирование координат
  centerMapOnCoordinates(coordinates) {
    if (this.map) {
      this.map.flyTo({
        center: coordinates,
        zoom: 13,
        essential: true, // гарантирует плавное перемещение
      });
    }
  }

  deleteMarkerFotType(type) {
    if (this.markers[type]) {
      this.markers[type].remove();
      this.markers[type] = null;
    }
  }

  // Функция для добавления нового маркера на карту
  addMarkerToMap(coordinates, type) {
    this.deleteMarkerFotType(type);

    let color = this._getColorMarcer(type);

    if (this.map) {
      const marker = new mmrgl.Marker({ color: color })
        .setLngLat(coordinates)
        .addTo(this.map);
      this.markers[type] = marker;
    }
  }

  _addMarkerRestaurants(markers) {
    markers?.map((i) => {
      try {
        let { priority, title, id, food, foodPies } = i;

        console.log(i);
        const htmlMarrker = this._getHtmlMarker(
          priority,
          title,
          id,
          food,
          foodPies
        );

        const lat = i.point.coordinates[1];
        const lon = i.point.coordinates[0];

        new mmrgl.Marker({
          element: htmlMarrker,
          anchor: "bottom",
        })
          .setLngLat([+lat, +lon])
          // .setPopup( new mmrgl.Popup().setHTML( `<div>Информация</div>` ))

          .addTo(this.map);
      } catch (e) {
        console.error(e);
      }
    });
  }

  initializeMap(markers) {
    mmrgl.accessToken = REACT_APP_VKMAPS_API_KEY;
    this.map = new mmrgl.Map({
      container: "map",
      zoom: 8,
      center: [37.6165, 55.7505],
      style: "mmr://api/styles/main_style.json",
      hash: true,
    });

    // Добавляем линейку масштаба
    this.map.addControl(new mmrgl.ScaleControl(), "bottom-right");
    // Позволяет пользователям увеличивать и уменьшать масштаб карты, а также возвращаться к исходному положению
    this.map.addControl(new mmrgl.NavigationControl(), "top-right");

    //  Добавляет кнопку для определения местоположения пользователя и центрирует карту на этом месте
    this.map.addControl(new mmrgl.GeolocateControl(), "top-right");

    this._addMarkerRestaurants(markers);

    // Переменная для хранения текущего попапа
    //let currentPopup = null;
    // Обработчик события правого клика
    this.map.on("contextmenu", (e) => {
      const coordinates = e.lngLat;

      // Закрываем старый попап, если он существует
      if (this.currentPopup) {
        this.currentPopup.remove();
      }

      // Создаем новый попап
      this.currentPopup = new mmrgl.Popup()
        .setLngLat(coordinates)
        //.setHTML( this._getPopupHandle()) // Замените на нужный вам контент
        .setDOMContent(this._getPopupHandle(coordinates))
        .addTo(this.map);
    });
  }

  removeMap() {
    if (this.map) {
      this.map.remove();
    }
  }

  // расшифровка маршрута по документации
  _decodePolyline(polyline) {
    let index = 0,
      lat = 0,
      lng = 0,
      coordinates = [],
      shift = 0,
      result = 0,
      byte = null,
      latitude_change,
      longitude_change,
      factor = 1000000;

    while (index < polyline.length) {
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = polyline.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = result & 1 ? ~(result >> 1) : result >> 1;
      shift = result = 0;

      do {
        byte = polyline.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push([lng / factor, lat / factor]);
    }

    return coordinates;
  }

  // удаление старого роута
  deleteRout() {
    if (this.map.getLayer("route")) {
      this.map.removeLayer("route");
    }
    if (this.map.getSource("route")) {
      this.map.removeSource("route");
    }
  }

  buildRoutOnMap(shape) {
    this.deleteRout();

    let decodedRoute = this._decodePolyline(shape);

    // Добавление линии, представляющей маршрут
    this.map.addLayer({
      id: "route",
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: decodedRoute,
          },
        },
      },
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#888",
        "line-width": 8,
      },
    });
  }
}

const map = new MapVKSingleton();

export default map;
