import { decodePolyline } from "utils";

export default function getPointRout(latStart, lonSrart, latEnd, lonEnd, setData, 
  setDataForRoutDistanceTime) {

    //console.log(latStart, lonSrart, latEnd, lonEnd)
      const VKMAP = process.env.REACT_APP_VKMAPS_API_KEY;
     //////////////
     const url = `https://maps.vk.com/api/directions?api_key=${VKMAP}`;
     //const url = `https://demo.maps.vk.com/api/directions`;
  const params = {
    locations: [
      { lon: lonSrart, lat: latStart },
      { lon: lonEnd, lat: latEnd }
    ],
    costing: "auto",
    costing_options: {
      auto: {
        use_border_crossing: 0
      }
    },
    units: "kilometers",
    id: "my_route"
  };
  
  fetch(url, {
    method: "POST",
    body: JSON.stringify(params)
  })
    .then(response => response.json())
    .then(data => {

      console.log(data)
  const shape = data.trips[0].trip.legs[0].shape;
  console.log(data)

  const infoForRout = {distanse: data.trips[0].trip.summary.length, time: data.trips[0].trip.summary.time}
        setDataForRoutDistanceTime(infoForRout)  
      setData(decodePolyline(shape))
    } )
    .catch(error => console.error(error));
  }