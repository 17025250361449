
import { loadingData } from "pages/manager/WorkspacePage/components/MapOsmLeaflet/utils";
import { useState } from "react";
const initialRestaurants = () => {

   // const [restaurants, setRestaurants] = useState([]);


      // запрос данных по ресторанам
  const loadRestaurantData = loadingData("restaurant", {
    limit: 10000,
    where: { isPublished: true },
    attributes: [
      "id",
      "title",
      "address",
      "isPublished",
      "point",
      "priority",
      "objects",
      "food",
      "foodPies"
    ],
    order: [["title", "ASC"]],
  });
    return {loadRestaurantData}

}

export default initialRestaurants;