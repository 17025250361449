import React, { useState } from "react";
import { Col, InputNumber, Row, Slider, Space, Typography } from "antd";
import { ManagerOfferContext } from "context";
import handlerAddFilter from "../utils/handlerAddFilter";
const IntegerStep = () => {
  const [inputValue, setInputValue] = useState(20);

  const { setDataForFilterHalls } =
    React.useContext(ManagerOfferContext);

  // запись нового значения
  const onChange = (item) => {
    setInputValue(item);
    handlerAddFilter(item, setDataForFilterHalls, "boarding");
  };

  return (
    <Row>
      <Col span={12}>
        <Slider
          min={1}
          max={20}
          onChange={onChange}
          value={typeof inputValue === "number" ? inputValue : 0}
        />
      </Col>
      <Col span={7}>
        <InputNumber
          min={1}
          max={20}
          style={{
            margin: "0 8px",
          }}
          value={inputValue}
          onChange={onChange}
        />
      </Col>
      <Col span={5}>
        <Typography.Paragraph level={4}>Посадка</Typography.Paragraph>
      </Col>
    </Row>
  );
};
const DecimalStep = () => {
  const { setDataForFilterHalls } =
    React.useContext(ManagerOfferContext);

  const [inputValue, setInputValue] = useState(1);
  const onChange = (item) => {
    if (isNaN(item)) {
      return;
    }
    setInputValue(item);
    handlerAddFilter(item, setDataForFilterHalls, "fit");
  };
  return (
    <Row>
      <Col span={12}>
        <Slider
          min={1}
          max={100}
          onChange={onChange}
          value={typeof inputValue === "number" ? inputValue : 0}
          step={1}
        />
      </Col>
      <Col span={7}>
        <InputNumber
          min={1}
          max={100}
          style={{
            margin: "0 8px",
          }}
          step={1}
          value={inputValue}
          onChange={onChange}
        />
      </Col>
      <Col span={5}>
        <Typography.Paragraph level={4}>Вместим.</Typography.Paragraph>
      </Col>
    </Row>
  );
};

function FilterForCapacityPeople() {
  return (
    <Space
      style={{
        width: "100%",
      }}
      direction="vertical"
    >
      <Typography.Paragraph level={4}>
        Выберите диапазон по количество человек:
      </Typography.Paragraph>
      <IntegerStep />
      <DecimalStep />
    </Space>
  );
}
export default FilterForCapacityPeople;
