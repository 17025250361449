import { MODELS } from "constants";
import React, { useEffect, useState } from "react";
import { getObjKeyName, api } from "utils";

import { read, utils, writeFile } from "xlsx";
import buildCategory from "./buildCatrgory";
import handleLoadinfForImport from "./handleLoadinfForImport";
import startExporsHalls from "./startExporsHalls";
import saveExel from "./saveExel";

export default function useExports() {
  const [data, setData] = useState([]);
  const [dataToFile, setDataToFile] = useState([]);

// фнкция запуска экспорта
  function exportAllinFileXlsx() {
    console.log("Начат экспорт");

    fetch(`${process.env.REACT_APP_SERVER_URL}/api/halls/sheet`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => setData(data));
  }

  // как только все загрузилось начинаем мождернизацию и дозагрузку объектов
  useEffect(() => {
    if (data.length > 0) {      
      startExporsHalls(data, saveExel);
    }
  }, [data]);


  return { exportAllinFileXlsx };
}

