import {
  AttributionControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
  Circle,
  Polyline,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { filtringData, initialData, loadingData } from "./utils";
import React, { memo, useEffect, useMemo, useState } from "react";
import MyMarker from "./MyMarker";
import searchMarcer from "../../../../../assets/icon/marker-pin.svg";
import markerfilter from "../../../../../assets/icon/marker-filter.svg"
import { LeafletRoutingMachine } from "./components";
import { ManagerOfferContext } from "context";
import { api } from "utils";
import getRestaurantsAfterFiltring from "./utils/getRestaurantsAfterFiltring";
import { Tag } from "antd";
export default React.memo(function OSMLEAFLET({
  //export default function OSMLEAFLET({
  datVisibleObjects,  
  selectPosition,
  setSelectPosition,
  setFirstMarker,
  setSecondMarker,
  firstMarker,
  secondMarker,
  radiusFilter,
  seTtimeAndDistanceAboutRoute,
 // dataPointForRoutVKMap,
}) {
  const [centrMap, setCentrMap] = useState([55.767193, 37.608239]);
  const [circle, setCircle] = useState(null);
  
  // получаем значение установленных фильтров по объектам
  const {
    filterRestaurants,    
    dataForFilterReataurants,
    dataForFilterHalls,    
    dataPointForRoutVKMap
  } = React.useContext(ManagerOfferContext);




   console.log(datVisibleObjects,  
  //   selectPosition,
  //   setSelectPosition,
  //   setFirstMarker,
  //   setSecondMarker,
  //   firstMarker,
  //   secondMarker,
  //   radiusFilter,
  //   seTtimeAndDistanceAboutRoute,
  );

  // console.log(filterRestaurants,    
  //   dataForFilterReataurants,
  //   dataForFilterHalls,    
  //   dataPointForRoutVKMap);
  
  // тестирую фильт!!!
  const [dataH, setDataH] = useState({});
  const [dataR, setDataR] = useState({});

  // установка центра при первой загрузке
  const position = [55.767193, 37.608239];

  // постановка маркера после поиска
  const locationSelection = [selectPosition?.lat, selectPosition?.lon];

  // стилизация маркера поиска
  const icon = L.icon({
    iconUrl: searchMarcer,
    iconSize: [46, 60],
    iconAnchor: [23, 60],
  });

   // стилизация маркера поиска
   const iconFilter = L.icon({
    iconUrl: markerfilter,
    iconSize: [46, 60],
    iconAnchor: [23, 60],
  });



  // запрос данных по ресторанам
  const loadRestaurantData = loadingData("restaurant", {
    limit: 10000,
    attributes: [
      "id",
      "title",
      "address",
      "isPublished",
      "point",
      "priority",
      "objects",
    ],
    order: [["title", "ASC"]],
  });
  ///////////////////////////////////////////
  // тест переработать все в отдельные функции !!!!

  useEffect(() => {
    console.log("проверка рендера")
    async function testRest() {
      if (!dataForFilterReataurants) {
        // объект пустой
        return;
      }
      // if (Object.keys(dataForFilterReataurants).length === 0) {
      //   // объект пустой
      //   return; // или используйте другую конструкцию для прерывания исполнения кода
      // }

      //console.log(dataForFilterReataurants);
      console.log("рестораны!! запрос");
      await api
        .getDataAfterFilter({
          model: "restaurant",
          params: {
            limit: 10000,
            attributes: [
              "id",
              "title",
              "address",
              "isPublished",
              "point",
              "priority",
              "objects",
            ],
            order: [["title", "ASC"]],
          },
          //data: [{objects: [{"title": 410},{"title": 413}], mondayStart: 1.00, mondayEnd: 18.00, tuesdayStart: 1.00, tuesdayEnd: 23.59 }],
          data: [dataForFilterReataurants, { isPublished: true }], //{isPublished: true}
        })
        .then(({ data }) => {
          //console.log("!!рестораны!!", data);
          setDataR(data);
        });
      //.finally(() => setFetching(false));
    }

    testRest();
  }, [dataForFilterReataurants]);

  // запускаем фильтр по залам когда изменяется состояние фильтров
  useEffect(() => {
    async function testHall() {
      if (!dataForFilterHalls) {
        // объект пустой
        return;
      }

      await api
        .getDataAfterFilter({
          model: "hall",
          params: {
            limit: 10000,
            attributes: [
              "id",
              "title",
              "address",
              "isPublished",
              "point",
              "priority",
              "objects",
            ],
            order: [["title", "ASC"]],
          },
          // data: [{boarding: 2, fit: 50, numberOfFloors: 3}] ,
          data: [dataForFilterHalls],
        })
        .then(({ data }) => {
          // console.log("Залы ", data);
          setDataH(data);
        });
      //.finally(() => setFetching(false));
    }
    testHall();
  }, [dataForFilterHalls]);

  ///////////////////////////////////////////
  // запрос данных по остальным объектам
  const loadObjectData = loadingData("object", {
    limit: 10000,
    order: [
      ["type", "ASC"],
      ["title", "ASC"],
    ],
  });

  // фильтрация объектов (кладбища, морги, крематорий)
  const filterObjectData = filtringData(loadObjectData, datVisibleObjects);
  // отфильтровываем не опубликованные рестораны (по старому минуя фильтр)
  // const isPublishedRestaurantData = loadRestaurantData?.filter(
  //   (i) => i.isPublished !== false
  // );
  // проверяем все полученные данные по ресторанам и залам
  const isMap =
    dataR != null &&
    Object.keys(dataR).length > 0 &&
    dataH != null &&
    Object.keys(dataH).length > 0
      ? true
      : false;
  // если в полученных ресторанах есть id совпадающие с залами (которые соответствуют фильтрам) отображаем на карте
  const toMap = isMap
    ? dataR.rows.filter((item1) => {
        return dataH.rows.some((item2) => item2.idRestaurants === item1.id);
      })
    : [];

  // убираем круговой фильтр
  useEffect(() => {
    if (radiusFilter.status != "CREATING") {
      setCircle(null);
    }
  }, [radiusFilter]);

  // контейнер который слушает местоположение нажатия для постановки кругового фильтра
  const MapEvents = () => {
    if (radiusFilter.status === "CREATING") {
      useMapEvents({
        click(e) {
          const newCircle = (
            <Circle
              center={[e.latlng.lat, e.latlng.lng]}
              pathOptions={{ fillColor: "blue" }}
              radius={radiusFilter.radius}
            />
          );

          setCircle(newCircle);
        },
      });
      return false;
    }
  };

  // установка маркера на центр при поиске адреса
  const RecenterAutomatically = () => {

    
    if (selectPosition != null) {
      const map = useMap();
      useEffect(() => {
        map.setZoom(17);
        map.setView([+selectPosition.lat, +selectPosition.lon]);        
      }, [selectPosition]);
      return null;
    }
  };

function selectRestaurants(position) {
  const map = useMap();
  map.setView(position);
}

console.log(filterRestaurants, "ll")
  
  console.log("render osm");
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        zIndex: 1,
        backgroundColor: "red",
      }}
    >
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={centrMap}
        zoom={10}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapEvents />
        <RecenterAutomatically></RecenterAutomatically>
        {circle}
        {selectPosition && (
          <Marker
            position={locationSelection}
            icon={icon}            
          >
            <Popup>
              Точка поиска
              <br />
              <Tag
                closeIcon
                color="volcano"
                onClick={() => setSelectPosition(null)}
              >
                убрать с карты точку поиска
              </Tag>
            </Popup>
          </Marker>
        )}
        {/* {isPublishedRestaurantData?.map((i) => ( */}
        {toMap?.map((i) => (
          <div key={Math.random()}>
            <MyMarker
              coordinates={i.point.coordinates}
              all={i}
              type={i.type}
              // setModalFor={setModalFor}
              // modalFor={modalFor}
              selectRestaurants={selectRestaurants}
              id={i.id}
              priority={i.priority}
              status={i.status}
            ></MyMarker>
          </div>
        ))} 
        {/* <MyComponent toMap={toMap} selectRestaurants={selectRestaurants} ></MyComponent> */}
        {filterObjectData?.map((i) => (
          <div key={Math.random()}>
            <MyMarker
            setSelectPosition={setSelectPosition}
              coordinates={i.point.coordinates}
              all={i}
              type={i.type}
              title={i.title}
              address={i.address}
              id={toString(i.id)}
            ></MyMarker>
          </div>
        ))}
{/* показываю объекты по которым фильтруют */}
        {filterRestaurants.map((i)=> (
          <div key={Math.random()}>
            <Marker icon={iconFilter}  position={i.point.coordinates}>
         <Popup>От</Popup> 
         <Tooltip direction="top" offset={[0, -55]} opacity={1} permanent>
        {i.title}
      </Tooltip>
      </Marker>
          </div>

        ))}

       { dataPointForRoutVKMap.length !==0 ?  <>
        
        <Marker icon={icon}  position={dataPointForRoutVKMap[0]}>
         <Popup>От</Popup> 
         
      </Marker>
      
       <Polyline positions={dataPointForRoutVKMap} color="blue" />
       <Marker icon={icon}  position={dataPointForRoutVKMap[dataPointForRoutVKMap.length - 1]}>
         <Popup>До</Popup> 
      </Marker>
       </>: <></>  }



        <LeafletRoutingMachine
          seTtimeAndDistanceAboutRoute={seTtimeAndDistanceAboutRoute}
          setFirstMarker={setFirstMarker}
          setSecondMarker={setSecondMarker}
          firstMarker={firstMarker}
          secondMarker={secondMarker}
        />       
      </MapContainer>
    </div>
  );
});
